import React, { useEffect, useState } from "react";
import 'antd/dist/antd.css';
import { 
  Statistic, 
  Row, 
  Col,
  Spin,
  Input,
  Tabs
} from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import api from "../../services/api";

import ListUsersTable from "../ListUsersTable";

import { Container, Header, Content } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function DashUserAdm() {
  const { TabPane } = Tabs
  
  const [usersPf, setUsersPf] = useState([]);
  const [usersPj, setUsersPj] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState([]);

  const { Search } = Input;

  function handleSearch(e) {
    setSearch(e.target.value);
    setLoading(true);
  }

  useEffect(() => {
    api.get("usuarios/pf").then((response) => {
      setUsersPf(response.data);
      setLoading(false);
    });
    api.get("usuarios/pj").then((response) => {
      setUsersPj(response.data);
      setLoading(false);
    });
  }, []);

  // Search
  useEffect(() => {
    api
      .get(`/usuarios/search-pf/?search=${search}`).then((response) => {
        setUsersPf(response.data);
        setLoading(false);
      });
      api
      .get(`/usuarios/search-pj/?search=${search}`).then((response) => {
        setUsersPj(response.data);
        setLoading(false);
      });
  }, [search]);

  return (
    <Container>
      <Header>
        <Row gutter={40}>
          <Col span={30}>
          { usersPf.length === 0 ? 
            <Spin indicator={antIcon} /> 
            :
            <Statistic title="Usuários Cadastradas" value={usersPf.length + usersPj.length} /> 
          }
          </Col>
        </Row>

        <div>
          <Search placeholder="Busca" loading={loading} onChange={handleSearch} style={{ width: '30vw' }} enterButton />
        </div>
      </Header>
     
     <Content>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Pessoa Física" key="1">
          {<ListUsersTable users={usersPf} type='pf' loading={loading} /> }
        </TabPane>
        <TabPane tab="Pessoa Jurídica" key="2">
          {<ListUsersTable users={usersPj} type='pj' loading={loading} /> }
        </TabPane>
      </Tabs>
     </Content>
    </Container>
  );
}

export default DashUserAdm;
