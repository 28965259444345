import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../components/Button'

import logo from '../../assets/Logo-CAE.png'
import logoSecti from '../../assets/Marca-Governo-de-Alagoas_White_SECTI.png'
import logoSelaj from '../../assets/logo-SELAJ.png'

import { Container, Content } from './styles'

function SignIn () {
  return (
    <Container>
      <Content>
        <img src={logo} alt="CAE" />

        <h1>Acesse o Sistema</h1>

        <Link to="/login" type="button">
          <Button type="submit">Login</Button>
        </Link>
        <Link to="/cadastro" type="button" disable>
          <Button type="submit" >Faça seu cadastro</Button>
        </Link>

        <div id="gov">
          <a href='http://www.cienciaetecnologia.al.gov.br/' target="_blank" rel="noopener noreferrer">
            <img src={logoSecti} alt="SECTI-AL" />
          </a>
          <a href='http://selaj.com.br/' target="_blank" rel="noopener noreferrer">
            <img src={logoSelaj} alt="SELAJ-AL" id='selaj' />
          </a>
        </div>

      </Content>

    </Container>
  )
}

export default SignIn
