import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #fff;
`;

export const Content = styled.div`
  background: rgba(255, 255, 255, 01);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 15px 10px rgb(0 0 0 / 10%);

  align-items: center;
    

  img {
    width: 28vw;
  }

  h1 {
    font-size: 2rem;
    margin: 10px;
  }

  h4 {
    color: rgba(0, 0, 0, 0.8)
  }

  small {
    font-weight: 500;
    padding: 5px;
    font-size: 1.1rem;
  }
  
  @media (max-width: 700px) {  
    img {
      width: 60vw;  
    }
  }
`

