import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.button`
  /* margin: 5px 0 0; */
  height: 46px;
  width: 100%;
  background: rgba(0, 170, 170, 0.9);
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${shade(0.09, "rgba(0, 170, 170, 0.9)")};
  }
`;
