import React, { useEffect, useState } from 'react';
import { FaIdCardAlt } from "react-icons/fa";
import { 
  Popconfirm, 
  Button, 
  Descriptions, 
  Card, 
  Empty,
  Tabs,
  Form,
  Select,
  Breadcrumb,
  Skeleton,
  Modal,
  message
} from 'antd';
import { 
  FolderOpenFilled,
  FilePdfFilled,
  HomeOutlined
} from '@ant-design/icons';
import moment from "moment";
import { FaTrash } from "react-icons/fa"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Container, Header } from './styles';

import api from "../../services/api";
import history from "../../services/history";

function ProfileUserPf({ match }) {
  const { TabPane } = Tabs;
  const { Option } = Select;

  const { id } = match.params;

  const [profile, setProfile] = useState();
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [loading, setLoading] = useState(true);

  // const { Panel } = Collapse;

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  useEffect(() => {
    api.get(`usuarios/profile-pf/?id=${id}`).then((response) => {
      setProfile(response.data);
    });
  }, [id]);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancelValidate = () => {
    setIsModalVisible(false);
  };

  const handleDelet = () => {
    setConfirmLoading(true);
    setTimeout(async () => {
      await api.delete(`/usuarios/profile-pf/?id=${id}`).then((response) => {
        setVisible(false);
        setConfirmLoading(false);

        history.push("/");
        toast.success(response.data.message);
        
      });
      
    }, 2000);
  };

  const onFinish = async (values) => {
    await api.patch(`/usuarios/profile-pf/?id=${id}`, values).then((response) => {
      setIsModalVisible(false);
      window.location.reload();
      message.success(response.data.message);
    }).catch((err) => {
      message.err(err.response.data.error);
    });

};

  const handleCancel = () => {
    setVisible(false);
  };


  return (
    <Container>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={{ pathname: "/" }}>
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {profile?.nome ? profile?.nome : < Skeleton />}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Header>
        <Popconfirm
          title="Excluir Cadastro ?"
          visible={visible}
          onConfirm={handleDelet}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button danger type="primary" onClick={showPopconfirm}>
            <FaTrash />
          </Button>
        </Popconfirm>
      </Header>
      <div id="divToPrint">
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        layout="vertical"
        style={{
          margin: "10px"
        }}
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <strong>
              <small>Última atualização - {moment(profile?.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
            </strong>
            <div id="validacao">
              <Button type="primary" onClick={showModal}>
                Validar Usuário
              </Button>
              <Modal
                className="modal"
                title="Validação de Perfil"
                visible={isModalVisible}
                width={300}
                onCancel={handleCancelValidate}
                footer={ 
                  <Button onClick={handleCancelValidate} danger>Cancelar</Button>
                }
              >
                <Form
                  {...layout}
                  name="basic"
                  initialValues={profile}
                  onFinish={onFinish}
                >
                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[{ required: true, message: 'Campo Obrigatório!' }]}        
                  >
                    <Select placeholder="Status">
                      <Option value="em_analise">Em análise</Option>
                      <Option value="valido">Válido</Option>
                      <Option value="invalido">Inválido</Option>
                    </Select>
                  </Form.Item>
                

                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </div>
        }
      >
        <Descriptions.Item
          span={3}
          style={{
            textAlign: "center"
          }}
        >
          <FaIdCardAlt size={60} color={"rgba(0, 180, 255, 0.9)"} />
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Nome:
            </strong>
          }
        >
          <small
            id='formatCapitalize'
          >
            { profile?.nome }
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              CPF:
            </strong>
          }
        >
          <small>
            {/* { profile.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5") || profile.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')} */}
            { profile?.cpf}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Telefone:
            </strong>
          }
        >
          <small>
            {profile?.telefone.replace(/^(\d\d)(\d{4})(\d)/,"($1) $2-$3")}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Email:
            </strong>
          }
        >
          <small>
            {profile?.email}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Data de Nascimento:
            </strong>
          }
        >
           <small>{moment(profile?.data_nascimento).utc("America/Alagoas").format("DD/MM/YYYY")}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Modalidade:
            </strong>
          }
        >
          <small>{profile?.modalidade}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Raça / Cor:
            </strong>
          }
        >
          <small>{profile?.raca_cor}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Gênero:
            </strong>
          }
        >
          <small>{profile?.genero}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Status:
            </strong>
          }
        >
          <strong>{profile?.status === 'em_analise' && 'Em análise'}</strong>
          <strong>{profile?.status === 'em_validacao' && 'Em validação'}</strong>
          <strong>{profile?.status === 'valido' && 'Válido'}</strong>
          <strong>{profile?.status === 'invalido' && 'Inválido'}</strong>
        </Descriptions.Item>

      </Descriptions>

      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        layout="vertical"
        style={{
          margin: "10px"
        }}
        title={
          <div className="title">
            <strong>
              <small>Endereço</small>
            </strong>
          </div>
        }
      >
        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Rua:
            </strong>
          }
        >
          <small>{profile?.rua}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Número:
            </strong>
          }
        >
          <small>{profile?.numero}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Bairro:
            </strong>
          }
        >
          <small>{profile?.bairro}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Complemento:
            </strong>
          }
        >
          <small>{profile?.complemento ? profile?.complemento : '-'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              CEP:
            </strong>
          }
        >
          <small>{profile?.cep}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Município:
            </strong>
          }
        >
          <small>{profile?.municipio}</small>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
          bordered
          size="small"
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          layout="vertical"
          style={{
            margin: "10px"
          }}
          title={
            <div className="title">
              <strong>
                <small>Atividade</small>
              </strong>
            </div>
          }
      >

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Profissional de educação física:
            </strong>
          }
        >
          <small>{profile?.profissional_edf ? 'Sim' : 'Não'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              CREF:
            </strong>
          }
        >
          <small>{profile?.cref ? profile?.cref : '-'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Nível de atuação:
            </strong>
          }
        >
            <small>{profile?.nivel_atuacao}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Função:
            </strong>
          }
        >
          <small>{profile?.funcao}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Recebe remuneração pela função?
            </strong>
          }
        >
          <small>{profile?.recebe_remuneracao ? 'Sim' : 'Não'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Valor da remuneração:
            </strong>
          }
        >
          <small>{!profile?.valor_remuneracao && '-'}</small>
          <small>{profile?.valor_remuneracao === 'ate_um_salario' && 'Até um salário'}</small>
          <small>{profile?.valor_remuneracao === 'ate_tres_salarios' && 'Até três salários'}</small>
          <small>{profile?.valor_remuneracao === 'acima_de_tres_salarios' && 'Acima de três salários'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Possui alguma deficiência ?
            </strong>
          }
        >
          <small>{profile?.pcd ? 'Sim' : 'Não'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Tipo de deficiência:
            </strong>
          }
        >
          <small>{!profile?.tipo_pcd && '-'}</small>
          <small>{profile?.tipo_pcd === 'fisica' && 'Física'}</small>
          <small>{profile?.tipo_pcd === 'intelectual' && 'Intelectual'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              É filiado a federação ?
            </strong>
          }
        >
          <small>{profile?.filiado_federacao ? 'Sim' : 'Não'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              É ou já foi beneficiado com o BOLSA-ATLETA ?
            </strong>
          }
        >
          <small>{profile?.beneficiado_bolsa_atleta ? 'Sim' : 'Não'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Tipo de bolsa:
            </strong>
          }
        >
          <small>{!profile?.tipo_bolsa && '-'}</small>
          <small>{profile?.tipo_bolsa === 'municipal' && 'Municipal'}</small>
          <small>{profile?.tipo_bolsa === 'estadual' && 'Estadual'}</small>
          <small>{profile?.tipo_bolsa === 'federal' && 'Federal'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Siatuação da Bolsa
            </strong>
          }
        >
          <small>{!profile?.tipo_bolsa && '-'}</small>
          <small>{profile?.situacao_bolsa === 'ativa' && 'Ativa'}</small>
          <small>{profile?.situacao_bolsa === 'inativa' && 'Inativa'}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          span={3}
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Breve Histórico
            </strong>
          }
        >
          <small>{profile?.breve_historico}</small>
        </Descriptions.Item>

      </Descriptions>
        
      </div>

      <Tabs 
        defaultActiveKey="1" 
        type="card"
        size="small"
        style={{
          padding: "10px"
        }}
      >
        {/* Arquivos */}
            
        <TabPane 
          tab="Arquivos" 
          key="1" 
          style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            flexWrap: 'wrap' 
          }}
        >
          {/* CPF */}
          {profile?.files?.cpf ?
            <Card
              style={{
                width: "50vw",
                marginTop: 16,
                textAlign: "center" 
              }}
              title='CPF'
              actions={[
                <a href={`${profile?.files?.cpf.url}`} target="_blank" rel="noopener noreferrer">
                  <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                </a>
              ]}
            >
              <FilePdfFilled style={{ fontSize: "2rem" }} />
              {profile.files?.cpf?.name}
            </Card>
          :
            <Empty
              description="Sem CPF"
              style={{
                width: "50vw",
                marginTop: 16,
                textAlign: "center" 
              }}
            />
          }

          {/* clipping */}
          {profile?.files?.clipping ?
            <Card
              style={{
                width: "50vw",
                marginTop: 16,
                textAlign: "center" 
              }}
              title='Currículo'
              actions={[
                <a href={`${profile?.files?.clipping.url}`} target="_blank" rel="noopener noreferrer">
                  <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                </a>
              ]}
            >
              <FilePdfFilled style={{ fontSize: "2rem" }} />
              {profile?.files?.clipping.name}
            </Card>
          :
            <Empty
              description='Sem Clipping'
              style={{
                width: "50vw",
                marginTop: 16,
                textAlign: "center" 
              }}
            />
          }
        </TabPane>
      </Tabs>
    </Container>
  );
}

export default ProfileUserPf;
