import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiPower } from "react-icons/fi";

import logo from "../../assets/Logo-CAE.png";
import logoSelaj from '../../assets/logo-SELAJ.png'
import Button from "../Button";

import { signOut } from "../../store/modules/auth/actions";

import { Container, Content, Menu, Footer } from "./styles";

function Sidebar() {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);

  function handleSignOut() {
    dispatch(signOut());
  };
  
  return (
    <Container className="siderbar">
      <Content>
      <nav>
        <img src={logo} alt="CAE" />
        <h2>{profile.cpf && profile.nome}</h2>
        <h2>{profile.cnpj && profile.razao_social}</h2>
        <h2>{profile.role && profile.nome}</h2>
        <h3>{profile.email}</h3>
      </nav>
      <Menu>
        <Link to="/">Início</Link>
        <Link to="/edit-perfil">Editar perfil</Link>
        { profile.role === 'super' && <Link to="/users/selaj">Usuários SELAJ</Link> }
        { profile.role === 'admin' && <Link to="/users/selaj">Usuários SELAJ</Link> }
      </Menu>
      <div>
        <Button onClick={handleSignOut}>
          <FiPower color={"#fff"} />
          <strong>Sair</strong>
        </Button>
      </div>
        
        <Footer>
          <div>
            <img src={logoSelaj} alt="SELAJ-AL" id='selaj' />
          </div>
          <p>© 2021 Todos os direitos reservados</p>
        </Footer>
      </Content>
    </Container>
  );
}

export default Sidebar;
