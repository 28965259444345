import React, { useState } from 'react'
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  InputNumber,
  Switch,
  Steps,
  message,
  Upload,
  Spin,
  Space
} from 'antd'
import moment from 'moment'
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {
  isValidPhone,
  isValidCNPJ
} from '@brazilian-utils/brazilian-utils'
import cep from 'cep-promise'

import api from '../../services/api'
import history from '../../services/history'

import { 
  beforeUploadFile, 
  customUploadFile, 
  handleDelFile 
} from '../../helpers/uploadFileFirebase'

import { Container } from './styles';
import Options from '../../utils/options'

function FormPj() {
  const { Step } = Steps
  const { RangePicker } = DatePicker

  const steps = [
    {
      title: 'Dados',
      content: 'First-content'
    },
    {
      title: 'Endereço',
      content: 'Second-content'
    },
    {
      title: 'Atividade',
      content: 'First-content'
    },
    {
      title: 'Anexos',
      content: 'First-content'
    },
    {
      title: 'Confirmação',
      content: 'Last-content'
    }
  ]

  const [current, setCurrent] = useState(0)
  const [dataUser, setDataUser] = useState(null)
  const [endereco, setEndereco] = useState(null)
  const [loading, setLoading] = useState(false)

  const [ataEleicaoAtualDiretoria, setAtaEleicaoAtualDiretoria] = useState(null)
  const [calendarioEsportivo, setCalendarioEsportivo] = useState(null)
  const [cnpj, setCnpj] = useState(null)
  const [estatuto, setEstatuto] = useState(null)
  //const [identidadePresidente, setIdentidadePresidente] = useState(null)
  //const [enderecoPresidente, setEnderecoPresidente] = useState(null)

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']
  const monthFormat = 'MM/YYYY'



  function checkCep (e) {
    setLoading(true)
    const numCep = e.target.value.replace(/\D/g, '')

    if (numCep.length === 8) {
      cep(numCep)
        .then((response) => {
          const {
            street: rua,
            neighborhood: bairro,
            city: municipio,
            cep,
            state: estado
          } = response

          setEndereco(
            {
              rua,
              bairro,
              municipio,
              cep,
              estado
            }
          )
          setLoading(false)
        })
        .catch((err) => {
          message.error('CEP não encontrado')
          setEndereco({})
        })
    }
  }
//Novo
  const onPrevious = () => setCurrent(current - 1)

  const setSignup = (data) => {
    setDataUser({ ...dataUser, ...data })
    setCurrent(current + 1)
  }

  const setFiles = (data) => {
    setCurrent(current + 1)
  }

  const onFinish = async (data) => {
    try {
      const files = {
        ata_eleicao_posse_atual_diretoria: ataEleicaoAtualDiretoria,
        calendario_esportivo: calendarioEsportivo,
        cnpj,
        estatuto,
        //endereco_presidente: enderecoPresidente,
        //identidade_presidente: identidadePresidente
      }
      const response = await api.post('/usuarios/create-pj', {
        ...dataUser,
        ...data,
        files,
        vigencia_direcao_inicio: data.vigencia_direcao && data.vigencia_direcao[0]._d,
        vigencia_direcao_fim: data.vigencia_direcao && data.vigencia_direcao[1]._d
      })

      history.push('/sucess', response.data)
    } catch (error) {
      if (error.response.data === 'ER_102_EMAIL_COMPANY_ALREADY_EXISTS') {
        message.error('Email já cadastrado.')
      }
    }
  }

  return (
    <Container>
      <div id="steps">
        <Steps current={current} size="small">
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>

      {/* Steage 1 */}
      {/* Dados */}
      {
        current === 0 &&
          <>
            <Form
              layout='vertical'
              initialValues={{
                remember: true
              }}
              onFinish={setSignup}
              name="dados"
            >
              <Form.Item
                label="Nome da Instituição"
                name="razao_social"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="CNPJ"
                name="cnpj"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  },
                  () => ({
                    validator (rule, value) {
                      
                      if (!value) {
                        return Promise.resolve()
                      }
                      if (value && value.replace(/\D/g, '')
                       && isValidCNPJ(value)) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('CNPJ Inválido')
                      }
                    }
                  })
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Telefone"
                name="telefone"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  },
                  () => ({
                    validator (rule, value) {
                      if (value && value.replace(/\D/g, '') &&
                          isValidPhone(value)) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Telefone Inválido')
                      }
                    }
                  })
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Data de Criação"
                name="data_criacao"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <DatePicker size='small' defaultValue={moment('01/01/1970', dateFormatList[0])} format={dateFormatList} />
              </Form.Item>

              <Form.Item
                label="Classificação"
                name="classificacao"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <Select>
                  <Select.Option value="federacao">Federação</Select.Option>
                  <Select.Option value="associacao">Associação</Select.Option>
                  <Select.Option value="clube">Clube</Select.Option>
                  <Select.Option value="liga">Liga</Select.Option>
                  <Select.Option value="outros">Outros</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Número de filiados"
                name="num_filiados"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <InputNumber />
              </Form.Item>

              <Form.Item>
                <Button
                  className="button"
                  type="primary"
                  htmlType="submit"
                >
                  Próximo
                </Button>

                <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
              >
                Anterior
               </Button>
              </Form.Item>

            </Form>
          </>
      }

      {/* Steage 2 */}
      {/* Endereço */}
      { current === 1 &&
        <>
          <Form
            layout='vertical'
            name="endereco"
            onFinish={setSignup}
            initialValues={{ remember: true }}
          >
            <Form.Item
              label="CEP"
              name="cep"
              onChange={checkCep}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Input />
            </Form.Item>

            { endereco &&
              <>
                <Form.Item
                  label="Logradouro"
                  name="rua"
                  initialValue={endereco.rua}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
               

                  label="Número"
                  name="numero"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!'
                      },
                    //() => ({
                    //   validator (rule, value) {
                    //     if (!value) {
                    //       return Promise.resolve()
                    //     }
                    //     if (value && !isNaN(value)) {
                    //       return Promise.resolve()
                    //     } else {
                    //       return Promise.reject('Número Inválido')
                    //     }
                    //   }
                    // })
                    
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Complemento"
                  name="complemento"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Bairro"
                  name="bairro"
                  initialValue={endereco.bairro}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Municipio"
                  name="municipio"
                  initialValue={endereco.municipio}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Estado"
                  name="estado"
                  initialValue={endereco.estado}
                  style={{ display: 'none' }}
                >

                  <Input style={{ display: 'none' }} />
                </Form.Item>
              </> 
            }
            {loading &&
                <div className='spin'>
                  <Spin />
                </div>
            }
            <Form.Item>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
              >
                Próximo
              </Button>
              <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
              >
                Anterior
               </Button>
            </Form.Item>
          </Form>
        </>
      }

      {/* Steage 3 */}
      {/* Atividade */}
      { current === 2 &&
        <>
          <Form
            layout='vertical'
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={setSignup}
          >

            <Form.Item
              label="Modalidade"
              name='modalidade'
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                  type:'array',
                }
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecione sua modalidade"
                options={Options.optionsModality}
              >
              </Select>
            </Form.Item>

            <Form.Item
              label="Sede própria ?"
              name="sede_propria"
              wrapperCol={{
                span: 7,
                offset: 0
              }}
            >
                <Switch checkedChildren="Sim" unCheckedChildren="Não" size='small' />
            </Form.Item>

              <Form.Item
                label="Desenvolve esporte para pessoas com deficiência ?"
                name="desenvolve_para_pcd"
                wrapperCol={{
                  span: 19,
                  offset: 0
                }}
              >
                  <Switch checkedChildren="Sim" unCheckedChildren="Não"  size='small'/>              
              </Form.Item>

            <Form.Item
              label="Recebe repasse de resurso ?"
              name="recebe_repasse_recurso"
              wrapperCol={{
                span: 12,
                offset: 0
              }}
            >
                <Switch checkedChildren="Sim" unCheckedChildren="Não" size='small' />
            </Form.Item>

            <Form.Item
              label="Origem do recurso"
              name='origem_recurso'
            >
              <Select>
                <Select.Option value="municipal">Municipal</Select.Option>
                <Select.Option value="estadual">Estadual</Select.Option>
                <Select.Option value="federal">Federal</Select.Option>
                <Select.Option value="confederacao">Confederação</Select.Option>
                <Select.Option value="privado">Privado</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
              >
                Próximo
              </Button>
            
           
              <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
              >
                Anterior
               </Button>
            </Form.Item>
          </Form>
        </>
      }

      {/* Steage 4 */}
      {/* UPLOADS */}
      { current === 3 &&
        <>
          <Form
            layout='vertical'
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={setFiles}
          >

            {/* ata_eleicao_posse_atual_diretoria */}
            <Form.Item
              label="Ata de eleição e posse da atual diretoria"
              name="ata_eleicao_posse_atual_diretoria"
              tooltip={{
                title: 'Importar somente arquivos PDF de até 10MB!',
                icon: <InfoCircleOutlined />
              }}
              labelCol={{ offset: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
                <Upload
                  name="ata_eleicao_posse_atual_diretoria"
                  beforeUpload={(file) => beforeUploadFile({ setState: setAtaEleicaoAtualDiretoria, file })}
                  customRequest={(file) => customUploadFile({setState: setAtaEleicaoAtualDiretoria, file})}
                  onRemove={(data) => handleDelFile({ state: ataEleicaoAtualDiretoria, data })}
                  maxCount={1}
                  accept='application/pdf'
                >
                    <Button
                      icon={<UploadOutlined />}
                      style={{
                        margin: '10px',
                        color: 'rgba(0, 190, 255, 0.9)',
                        border: '1px solid rgba(0, 190, 255, 0.9)'
                      }}
                    >
                      Upload
                    </Button>
                </Upload>
            </Form.Item>
            
            {/* calendario_esportivo */}
            <Form.Item
              name="calendario_esportivo"
              label="Calendario Esportivo"
              tooltip={{
                title: 'Importar somente arquivos PDF de até 10MB!',
                icon: <InfoCircleOutlined />
              }}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Upload
                name="calendario_esportivo"
                beforeUpload={(file) => beforeUploadFile({ setState: setCalendarioEsportivo, file })}
                customRequest={(file) => customUploadFile({setState: setCalendarioEsportivo, file})}
                onRemove={(data) => handleDelFile({ state: calendarioEsportivo, data })}
                maxCount={1}
                accept='application/pdf'
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    margin: '10px',
                    color: 'rgba(0, 190, 255, 0.9)',
                    border: '1px solid rgba(0, 190, 255, 0.9)'                  }}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            {/* cnpj */}
            <Form.Item
              name="cnpj"
              label="CNPJ"
              tooltip={{
                title: 'Importar somente arquivos PDF de até 10MB!',
                icon: <InfoCircleOutlined />
              }}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Upload
                name="cnpj"
                beforeUpload={(file) => beforeUploadFile({ setState: setCnpj, file })}
                customRequest={(file) => customUploadFile({setState: setCnpj, file})}
                onRemove={(data) => handleDelFile({ state: cnpj, data })}
                maxCount={1}
                accept='application/pdf'
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    margin: '10px',
                    color: 'rgba(0, 190, 255, 0.9)',
                    border: '1px solid rgba(0, 190, 255, 0.9)'
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            {/* estatuto */}
            <Form.Item
              name="estatuto"
              label="Estatuto"
              tooltip={{
                title: 'Importar somente arquivos PDF de até 10MB!',
                icon: <InfoCircleOutlined />
              }}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Upload
                name="estatuto"
                beforeUpload={(file) => beforeUploadFile({ setState: setEstatuto, file })}
                customRequest={(file) => customUploadFile({setState: setEstatuto, file})}
                onRemove={(data) => handleDelFile({ state: estatuto, data })}
                maxCount={1}
                accept='application/pdf'
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    margin: '10px',
                    color: 'rgba(0, 190, 255, 0.9)',
                    border: '1px solid rgba(0, 190, 255, 0.9)'
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            {/* identidade_presidente */}
            {/* <Form.Item
              name="identidade_presidente"
              label="Identidade Presidente"
              tooltip={{
                title: 'Importar somente arquivos PDF de até 10MB!',
                icon: <InfoCircleOutlined />
              }}
            >
              <Upload
                name="identidade_presidente"
                beforeUpload={(file) => beforeUploadFile({ setState: setIdentidadePresidente, file })}
                customRequest={(file) => customUploadFile({setState: setIdentidadePresidente, file})}
                onRemove={(data) => handleDelFile({ state: identidadePresidente, data })}
                maxCount={1}
                accept='application/pdf'
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    margin: '10px',
                    color: 'rgba(0, 190, 255, 0.9)',
                    border: '1px solid rgba(0, 190, 255, 0.9)'
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item> */}

            {/* endereco_presidente */}
            {/* <Form.Item
              name="endereco_presidente"
              label="Comprovante Endereço Presidente"
              tooltip={{
                title: 'Importar somente arquivos PDF de até 10MB!',
                icon: <InfoCircleOutlined />
              }}
            >
              <Upload
                name="endereco_presidente"
                beforeUpload={(file) => beforeUploadFile({ setState: setEnderecoPresidente, file })}
                customRequest={(file) => customUploadFile({setState: setEnderecoPresidente, file})}
                onRemove={(data) => handleDelFile({ state: enderecoPresidente, data })}
                maxCount={1}
                accept='application/pdf'
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    margin: '10px',
                    color: 'rgba(0, 190, 255, 0.9)',
                    border: '1px solid rgba(0, 190, 255, 0.9)'
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item> */}

            <Form.Item>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
              >
                Próximo
              </Button>
              <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
              >
                Anterior
               </Button>
            </Form.Item>
          </Form>
        </>
      }

      {/* Steage 5 */}
      {/* confirmação */}
      { current === 4 &&
        <>
          <Form
            layout='vertical'
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
          >

              <Form.Item
                label="Espaço adequado ?"
                name="espaco_adequado"
                wrapperCol={{
                  span: 10,
                  offset: 0
                }}
              >
                  <Switch checkedChildren="Sim" unCheckedChildren="Não" size='small' />
              </Form.Item>

            <Form.Item
              label="Responsável Legal"
            >
              <Space 
                align="baseline"
                direction='horizontal'
              >
                <Form.Item
                    label="Nome"
                    name="nome_responsavel"
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório!'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email_responsavel"
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: 'Campo obrigatório!'
                      }
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Telefone"
                    name="telefone_responsavel"
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório!'
                      },
                      () => ({
                        validator (rule, value) {
                          if (value && value.replace(/\D/g, '') &&
                              isValidPhone(value)) {
                            return Promise.resolve()
                          } else {
                            return Promise.reject('Telefone Inválido')
                          }
                        }
                      })
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
              </Space>
            </Form.Item>

            <Form.Item
                label="Período de vigência da direção/presidência"
                name="vigencia_direcao"
              >
                <RangePicker 
                  defaultValue={moment('01/2021', monthFormat)} 
                  format={monthFormat} 
                  picker="month"
                  placeholder=''
                  size='small'
                />
              </Form.Item>

            <Form.Item
              label="Breve histórico da Instituição"
              name="breve_historico"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  min: 8,
                  message: 'Password deve ter no mínimo 8 caracteres.!'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confime"
              dependencies={['password']}
              name="confirm"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Confirmação do password é obrigatória!'
                },
                ({ getFieldValue }) => ({
                  validator (rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Password não confere!')
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
              >
                Cadastrar
              </Button>
              <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
              >
                Anterior
               </Button>
            </Form.Item>
          </Form>
        </>
      }
    </Container>
  )
}

export default FormPj;