import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;

  padding-top: 20px;

  @media (max-width: 700px) {
      width: 70vw;
      padding: 10px;
      
    form {
        padding: 10px;
        display: flex;
        flex-direction: column;
        width: 100vw;

        input {
          width: 60vw;
        }

        div[type='select'] {
          width: 60vw;
        }

        .input-password {
          width: 60vw;
        }

        .select-option {
          width: 60vw;
        }
    }
  }
`;
