const Options = {
    optionsModality: [
        {
            value: ' Atletismo; '
        },
        {
            value: ' Automobilismo; '
        },
        {
            value: ' Badminton; '
        },
        {
            value: ' Basquete; '
        },
        {
            value: ' Basquete 3×3; '
        },
        {
            value: ' Beachtennis; '
        },
        {
            value: ' Beisebol; '
        },
        {
            value: ' Biribol; '
        },
        {
            value: ' BMX; '
        },
        {
            value: ' Bobsled; '
        },
        {
            value: ' Bocha; '
        },
        {
            value: ' Bodyboard; '
        },
        {
            value: ' Boliche; '
        },
        {
            value: ' Boxe; '
        },
        {
            value: ' Canoagem; '
        },
        {
            value: ' Capoeira; '
        },
        {
            value: ' Cheerleading; '
        },
        {
            value: ' Ciclismo de Estrada; '
        },
        {
            value: ' Ciclismo de Pista; '
        },
        {
            value: ' Corrida de Rua; '
        },
        {
            value: ' Cross Country; '
        },
        {
            value: ' Crossfit; '
        },
        {
            value: ' Curling; '
        },
        {
            value: ' Dama; '
        },
        {
            value: ' Dança Esportiva; '
        },
        {
            value: ' Escalada Esportiva; '
        },
        {
            value: ' Esgrima; '
        },
        {
            value: ' E-Sports; '
        },
        {
            value: ' Fisiculturismo; '
        },
        {
            value: ' Futebol; '
        },
        {
            value: ' Futebol Americano; '
        },
        {
            value: ' Futebol de Areia; '
        },
        {
            value: ' Futevôlei; '
        },
        {
            value: ' Futsal; '
        },
        {
            value: ' Ginástica Artística; '
        },
        {
            value: ' Ginástica Ritmica; '
        },
        {
            value: ' Goalball; '
        },
        {
            value: ' Golfe; '
        },
        {
            value: ' Handebol; '
        },
        {
            value: ' Highline; '
        },
        {
            value: ' Hipismo; '
        },
        {
            value: ' Hóquei; '
        },
        {
            value: ' Hóquei de Grama; '
        },
        {
            value: ' Jiu-Jitsu; '
        },
        {
            value: ' Jogo de Malha; '
        },
        {
            value: ' Judô; '
        },
        {
            value: ' Karatê; '
        },
        {
            value: ' Kickboxing; '
        },
        {
            value: ' Kitesurfe; '
        },
        {
            value: ' Kung Fu; '
        },
        {
            value: ' Levantamento de Peso; '
        },
        {
            value: ' Luge; '
        },
        {
            value: ' MMA; '
        },
        {
            value: ' Motociclismo; '
        },
        {
            value: ' Mountain Biking; '
        },
        {
            value: ' Muay Thai; '
        },
        {
            value: ' Nado Artístico; '
        },
        {
            value: ' Natação; '
        },
        {
            value: ' Parapente; '
        },
        {
            value: ' Patinação; '
        },
        {
            value: ' Patinação Artística no Gelo; '
        },
        {
            value: ' Patinação de Velocidade no Gelo; '
        },
        {
            value: ' Polo Aquático; '
        },
        {
            value: ' Rafting; '
        },
        {
            value: ' Remo; '
        },
        {
            value: ' Rugby; '
        },
        {
            value: ' Saltos Ornamentais; '
        },
        {
            value: ' Skate; '
        },
        {
            value: ' Skeleton; '
        },
        {
            value: ' Ski Esportivo; '
        },
        {
            value: ' Slackline; '
        },
        {
            value: ' Snowboard Esportivo; '
        },
        {
            value: ' Softball; '
        },
        {
            value: ' Squash; '
        },
        {
            value: ' Stand Up Paddle; '
        },
        {
            value: ' Surfe; '
        },
        {
            value: ' Taekwondo; '
        },
        {
            value: ' Tiro com Arco; '
        },
        {
            value: ' Tiro Esportivo; '
        },
        {
            value: ' Triathlon; '
        },
        {
            value: ' Tênis; '
        },
        {
            value: ' Tênis de Mesa; '
        },
        {
            value: ' Vela; '
        },
        {
            value: ' Voo Livre; '
        },
        {
            value: ' Voleibol; '
        },
        {
            value: ' Voleibol de Praia; '
        },
        {
            value: ' Windsurf; '
        },
        {
            value: ' Wingsuit; '
        },
        {
            value: ' Wrestling; '
        },
        {
            value: ' Xadrez; '
        }
    ]
}

export default Options;