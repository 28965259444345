import { message } from 'antd'
import { storage } from '../services/firebase'
import crypto from 'crypto'

export const beforeUploadFile = ({ file }) => {
    const isFile = file.type.indexOf('application/pdf') === 0
    if (!isFile) {
      message.error('Importar somente arquivos PDF!')
    }

    // You can remove this validation if you want
    //const isLt5M = file.size / 1024 / 1024 < 10
    //if (!isLt5M) {
    //  message.error('Arquivo precisa ter menos de 10MB!')
    //} && isLt5M
    return isFile 
  }

export const customUploadFile = async ({ setState, file }) => {
    const { file: isFile } = file
    const storageRef = await storage.ref()
    const newHash = crypto.randomBytes(4).toString('hex')
    const name = isFile.name.split('.')[0]
    const DocFile = storageRef.child(`/teste/${name}-${newHash}`).put(isFile)

    DocFile.on('state_changed', function (snapshot) {
    }, function (error) {
      isFile.onError(error)
    }, function () {
      DocFile.snapshot.ref.getDownloadURL().then(async function (downloadURL) {
        file.onSuccess(null, downloadURL)
        setState({
          url: downloadURL,
          hash: newHash,
          filename: file.filename,
          name: isFile.name
        })
      })
    })
  }

export const handleDelFile = async ({ data, state, edit }) => {
    if (state) {
      const storageRef = await storage.ref()
      const name = data.name.split('.')[0]
      const desertRef = storageRef.child(`teste/${name}-${state?.hash}`)
      desertRef.delete()
    }
    if (edit) {
      const storageRef = await storage.ref()
      const name = edit.name.split('.')[0]
      const desertRef = storageRef.child(`teste/${name}-${edit?.hash}`)
      desertRef.delete()

      return true
    }
  }