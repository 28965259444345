import styled from 'styled-components'
import { shade } from 'polished'

export const Container = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  width: 100%;
  max-width: 550px;

  img {
    width: 28vw;
  }

  #gov {
    img {
      width: 145px;
      padding: 5px;
    }

    #selaj {
      width: 170px;
      height: 60px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

  }
  

  h1 {
    font-size: 1.9rem;
    margin: 10px;
    font-weight: 500;
    color: rgba(0, 60, 170, 0.9);
  }

  form {
    margin: 25px 0;
    width: 340px;
    text-align: center;
    font-size: 1rem;
    color: #f64c75;

    button {
      width: 100%;
    }

    a {
      display: block;
      color: #333;
      margin-top: 15px;
      font-size: 1.3rem;
      opacity: 0.8;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }
    }
  }

  > a {
    color: rgba(0, 60, 170, 0.9);
    font-size: 1.6rem;
    opacity: 0.8;
    text-decoration: none;
    transition: background 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }

    &:hover {
      color: ${shade(0.05, 'rgba(0, 60, 170, 0.9)')};
    }
  }

  @media (max-width: 700px) {
    display: flex;
    margin-top: 0;
    
    img {
      width: 60vw;
      
    }

    button {
      margin: 20px 0 0;
      width: 80vw;
    }
  
  }
`