import React from "react";
import { useSelector } from "react-redux";

import DashUsers from "../../components/DashUsers";
import DashUserAdm from "../../components/DashUserAdm";

import { Container } from "./styles";

function Dashboard() {
  const profile = useSelector((state) => state.user.profile);

  return (
    <Container>
      { !profile.role ? <DashUsers /> : <DashUserAdm /> }
    </Container>
  );
}

export default Dashboard;
