import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";

import Main from "../pages/Main";
import Confirm from "../pages/Confirm";

import Dashboard from "../pages/Dashboard";
import EditingProfile from "../pages/EditingProfile";
import ProfileUserPf from "../pages/ProfileUserPf";
import ProfileUserPj from "../pages/ProfileUserPj";


import Error from "../pages/Error";
import ListUsersSelaj from "../pages/ListUsersSelaj";
import ForgotPassword from "../pages/ForgotPasword";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/login" component={SignIn} />

      <Route path="/sucess" component={Confirm} />

      <Route path="/cadastro" component={SignUp} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/edit-perfil" component={EditingProfile} isPrivate />

      <Route path="/user-pf/:id" component={ProfileUserPf} isPrivate />
      <Route path="/user-pj/:id" component={ProfileUserPj} isPrivate />

      <Route path="/users/selaj" component={ListUsersSelaj} isPrivate />
      { /*<Route path="/documentos-uteis" component={DocumentsUtils} isPrivate /> */}
      
      <Route path="/novasenha" component={ForgotPassword} /> 
      <Route path="/" component={Error} />
    </Switch>
  );
}
