import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: 2s;

  padding: 10px;

  #formatCapitalize {
    text-transform: capitalize;
  }

  div {
   padding: 5px 10px;
   background: #fff;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 40px;

  button {
    display: flex;
    align-items: center;
  }
`