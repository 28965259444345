import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

// import history from "../../../services/history";
import api from "../../../services/api";

import { 
  updateProfileSuccess, 
  updateProfileFailure, 
} from "./actions";

export function* updateProfile({ payload }) {
  try {
    const { data } = payload;
    
    if (data.type === 'pf') {
      const response = yield call(api.patch, "profile-pf", data);
      
      toast.success("Perfil atualizado com sucesso!");

      yield put(updateProfileSuccess(response.data));
    } else if(data.type === 'pj') {
      const response = yield call(api.patch, "profile-pj", data);
      toast.success("Perfil atualizado com sucesso!");

      yield put(updateProfileSuccess(response.data));
    } else if (data.type === 'selaj') {
      const response = yield call(api.patch, "/usuarios/selaj", data);
      toast.success("Perfil atualizado com sucesso!");

      yield put(updateProfileSuccess(response.data));
    }
  } catch (err) {
    toast.error(err.response.data.error);
    yield put(updateProfileFailure());
  }
}

export default all([
  takeLatest("@user/UPDATE_PROFILE_REQUEST", updateProfile)
]);

