import React from "react";
import { Link } from "react-router-dom";
import { FiArrowLeftCircle } from "react-icons/fi";
import { Result } from 'antd';

import { Container } from "./styles";

import logo from "../../assets/Logo-CAE.png";

function Error() {
  return (
    <Container>
      <div>
        <img src={logo} alt="CAE" />
        <Result
          status="404"
          title="404"
          subTitle="Desculpe, a página que você visitou não existe."
          extra={
              <Link to="/">
                <FiArrowLeftCircle size={20} /> {""}
                <p>Voltar</p>
              </Link>
          }
        />
        
      </div>
    </Container>
  );
}

export default Error;
