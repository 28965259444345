import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  height: 100vh;

  background: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  div {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    img {
      width: 20vw;
    }

    h1 {
      margin-top: 30px;
    }

    h2 {
      font-weight: 400;
    }

    a {
      color: #5271ff;
      font-size: 1.8rem;
      opacity: 0.8;
      text-decoration: none;

        &:hover {
          color: ${shade(0.05, "#5271ff")};
        }
      }
  }
`;
