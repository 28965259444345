import React from "react";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { Result, Button } from 'antd';

import logo from "../../assets/Logo-CAE.png";

import { Container, Content } from "./styles";

function Confirm() {
  return (
    <Container>
      <Content>
        <img src={logo} alt="CAE" />

        <Result
          status="success"
          title={
            <h4>Cadastro Realizado com Sucesso!</h4>
          }
          extra={[
            <Link to="/login" type="button">
              <Button type="primary" key="console">
                <FiLogIn />
                Faça Aqui seu login 
              </Button>
            </Link>
          ]}
        />
      </Content>
    </Container>
    
  );
}

export default Confirm;
