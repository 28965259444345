import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { 
    Statistic, 
    Row, 
    Col,
    Spin,
    Input,
    Card,
    Descriptions,
    Button,
    Modal,
    Form,
    Select,
    message
} from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import api from '../../services/api'
import history from '../../services/history'

import { Container, Content, Header } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ListUsersSelaj() {
    const profile = useSelector((state) => state.user.profile);

    const { Search } = Input;
    const { Meta } = Card;
    const { Option } = Select

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState([]);
    
    const [isModalVisibleNewUser, setIsModalVisibleNewUser] = useState(false);
    const [isModalVisibleUpdateUser, setIsModalVisibleUpdateUser] = useState(false);
    const [isModalVidibleDeleteUser, setIsModalVidibleDeleteUser]= useState(false);
    const [userUpdate, setUserUpdate] = useState();
    const [userDelete, setUserDelete] = useState()

    useEffect(() => {
        if (
            profile.role === 'undefined' ||
            profile.role === 'analista'
            ) {
                history.push('/')
            } else {
                api.get("usuarios/selaj").then((response) => {
                    setUsers(response.data);
                    setLoading(false);
                });
            }
    }, [profile]);

    function handleSearch(e) {
        setSearch(e.target.value);
        setLoading(true);
    }

    const showModalNewUser = () => {
        setIsModalVisibleNewUser(true);
    };

    const handleCancelNewUser = () => {
        setIsModalVisibleNewUser(false);
    };

    const onFinish = async (data) => {
        const { senha, ...rest } = data
        
        await api.post('/usuarios/selaj', { ...rest, password: 'Senha@123' }).then((response) => {
            setIsModalVisibleNewUser(false);
            window.location.reload();
            message.success(response.data.message);
        }).catch((err) => {
            message.error(`${err.response.data.error}`);
        });
    };

    // Search
  useEffect(() => {
    api
      .get(`/usuarios/search-selaj/?search=${search}`).then((response) => {
        setUsers(response.data);
        setLoading(false);
      });
  }, [search]);


  const addUser = (
    <>
        <Modal
        className="modal"
        title="Cadastro de Usuário SELAJ"
        visible={isModalVisibleNewUser}
        width={500}
        onCancel={handleCancelNewUser}
        footer={ 
            <Button onClick={handleCancelNewUser} danger>Cancelar</Button>
        }
    >
        <Form
            name='basic'
            layout='vertical'
            initialValues={{
                remember: true
            }}
            onFinish={onFinish}
        >
            <Form.Item
                label="Nome"
                name="nome"
                rules={[
                    {
                        required: true,
                        message: 'Campo obrigatório!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Cargo"
                name="cargo"
                rules={[
                    {
                        required: true,
                        message: 'Campo obrigatório!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        type: 'email',
                        required: true,
                        message: 'Campo obrigatório!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Permissão"
                name="role"
                rules={[
                    {
                        required: true,
                        message: 'Campo obrigatório!',
                    },
                ]}
            >
                <Select placeholder="Permissão">
                    <Option value="analista">Analista</Option>
                    <Option value="admin">Admin</Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Senha"
                name="senha"
            >
                <Input  defaultValue='Senha@123' disabled />
            </Form.Item>

            <Form.Item
                label="Telefone"
                name="telefone"
            >
                <Input  />
            </Form.Item>

            <Form.Item >
                <Button type="primary" htmlType="submit">
                    Cadastrar
                </Button>
            </Form.Item>
        </Form>
    </Modal>
    </>
  )

  const handleConfirmUpdateUser = async (data) => {
    await api.patch(`/usuarios/profile-selaj/?id=${userUpdate.id}`, data).then((response) => {
        setIsModalVisibleUpdateUser(false);
        window.location.reload();
        message.success(response.data.message);
    }).catch((err) => {
        message.error(`${err.response.data.error}`);
    });
  }
  const showModalUpdateUser = (user) => {
    setIsModalVisibleUpdateUser(true)
    setUserUpdate(user)
  };

  const handleConfirmDeleteUser = async () => {
    await api.delete(`/usuarios/selaj/?id=${userDelete.id}`).then((response) => {
        setIsModalVidibleDeleteUser(false);
        window.location.reload();
        message.success(response.data.message);
    }).catch((err) => {
        message.error(`${err.response.data.error}`);
    });
  }

    const showModalDeleteUser = (user) => {
        setIsModalVidibleDeleteUser(true);
        setUserDelete(user)
    };

    const cancelModalDeleteUser = () => {
        setIsModalVidibleDeleteUser(false);
    };

    const handleCancelUpdateUser = () => {
        setIsModalVisibleUpdateUser(false);
    };

  const updateUser = (
    <>
        <Modal
        className="modal"
        title="Atualizar Usuário SELAJ"
        visible={isModalVisibleUpdateUser}
        width={500}
        onCancel={handleCancelUpdateUser}
        footer={ 
            <Button onClick={handleCancelUpdateUser} danger>Cancelar</Button>
        }
    >
        <Form
            name='basic'
            layout='vertical'
            initialValues={userUpdate}
            onFinish={handleConfirmUpdateUser}
        >
            <Form.Item
                label="Permissão"
                name="role"
                rules={[
                    {
                        required: true,
                        message: 'Campo obrigatório!',
                    },
                ]}
            >
                <Select placeholder="Permissão">
                    <Option value="analista">Analista</Option>
                    <Option value="admin">Admin</Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Status"
                name="status"
                rules={[
                    {
                        required: true,
                        message: 'Campo obrigatório!',
                    },
                ]}
            >
                <Select placeholder="Status">
                    <Option value="ativo">Ativo</Option>
                    <Option value="inativo">Inativo</Option>
                </Select>
            </Form.Item>

            <Form.Item >
                <Button type="primary" htmlType="submit">
                    Atualizar
                </Button>
            </Form.Item>
        </Form>
    </Modal>
    </>
  )

  const deleteUser = (
    <>
        <Modal
        className="modal"
        title="Remover Usuário SELAJ"
        visible={isModalVidibleDeleteUser}
        width={500}
        onCancel={cancelModalDeleteUser}
        footer={ 
            <>
                <Button onClick={cancelModalDeleteUser} danger>Cancelar</Button>
                <Button onClick={handleConfirmDeleteUser} type="primary">Confirmar</Button>
            </>
        }
    >
        
        <p>Confirmar a exclusão do usuário <strong>{userDelete?.nome}</strong> ?</p>
    </Modal>
    </>
  )

    return (
        <Container>
            <Header>
                <Row gutter={40}>
                    <Col span={30}>
                    { users.length === 0 ? 
                        <Spin indicator={antIcon} /> 
                        :
                        <Statistic title="Usuários Cadastradas" value={users.length} /> 
                    }
                    </Col>
                </Row>

                <div>
                    <Button onClick={showModalNewUser} >
                        Add User
                    </Button>
                </div>
                {addUser}

                <div>
                <Search placeholder="Busca" loading={loading} onChange={handleSearch} style={{ width: '30vw' }} enterButton />
                </div>
            </Header>
            <Content>
                {updateUser}
                {deleteUser}
                {users.map(user => {
                    return (
                        <Card
                            style={{ width: 370, margin: 10 }}
                            actions={[
                                user.role !== 'super' && <EditOutlined key="edit" onClick={() => showModalUpdateUser(user)} />,
                                user.role !== 'super' && <DeleteOutlined hey='delete' onClick={() => showModalDeleteUser(user)} />
                            ]}
                        >
                            <Meta
                                description={
                                    <Descriptions bordered title={ <h3>{user.nome}</h3> } size='small'>
                                        <Descriptions.Item label='Cargo' span={3}>{user.cargo ? user.cargo : '-'}</Descriptions.Item>
                                        <Descriptions.Item label='Email' span={3}>{user.email}</Descriptions.Item>
                                        <Descriptions.Item label='Tipo' span={3}>{user.role}</Descriptions.Item>
                                        <Descriptions.Item label='Telefone' span={3}>{user.telefone ? user.telefone : '-'}</Descriptions.Item>
                                        <Descriptions.Item label='Status' span={3}>{user.status}</Descriptions.Item>
                                    </Descriptions>
                                }
                            />

                        </Card>
                    )
                    })}
            </Content>
        </Container>
    )
}

export default ListUsersSelaj;