import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    Form,
    Input,
    Button,
    Select,
    DatePicker,
    InputNumber,
    Switch,
    Tabs,
    Space
} from 'antd'
import moment from 'moment'
import {
    isValidPhone,
    isValidCPF,
    isValidCNPJ
} from '@brazilian-utils/brazilian-utils'

import { updateProfileRequest } from "../../store/modules/user/actions";

import { Container } from "./styles";
import Options from '../../utils/options'

function EditProfile() {
    const { TabPane } = Tabs;
    const { RangePicker } = DatePicker

    const dispatch = useDispatch();

    const { profile } = useSelector((state) => state.user);

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const monthFormat = 'MM/YYYY'

    const onFinish = async (data) => {
        const vigencia = {
            vigencia_direcao_inicio: data.vigencia_direcao && data.vigencia_direcao[0]._d,
            vigencia_direcao_fim: data.vigencia_direcao && data.vigencia_direcao[1]._d
        }
        if (!data.vigencia_direcao) {
            delete vigencia.vigencia_direcao_inicio
            delete vigencia.vigencia_direcao_fim
        }

        dispatch(updateProfileRequest({ 
            ...data,
            ...vigencia,
            type: profile.cnpj ? 'pj' : 'pf' 
        }))
    };

  return (
    <Container>
        <Tabs tabPosition="top"
            style={{
                color: "rgba(7, 128, 239, 0.9)"
            }}
        >
            <TabPane tab="Dados" key="1">
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={
                        profile
                    }
                >
                    <div>
                        {profile.cpf &&
                            <>
                                <Form.Item
                                    label="Nome"
                                    name="nome"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="CPF"
                                    name="cpf"
                                    disable="true"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                    },
                                    () => ({
                                        validator(rule, value) {
                                        if (!value.replace(/\D/g, '')) {
                                            return  Promise.reject('Informe seu CPF!');
                                        }
                                        if (value && value.replace(/\D/g, '') &&
                                            isValidCPF(value)) {
                                            return Promise.resolve();
                                            }  else {
                                            return Promise.reject("CPF Inválido");
                                            }
                                        },
                                    })
                                    ]}
                                    hasFeedback
                                >
                                    <Input />
                                </Form.Item>
                            </>
                        }
                        {profile.cnpj &&
                            <>
                                <Form.Item
                                    label="Nome da Instituição"
                                    name="razao_social"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="CNPJ"
                                    name="cnpj"
                                    disable="true"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                    },
                                    () => ({
                                        validator(rule, value) {
                                        if (!value.replace(/\D/g, '')) {
                                            return  Promise.reject('Informe seu CPF!');
                                        }
                                        if (value && value.replace(/\D/g, '') &&
                                            isValidCNPJ(value)) {
                                            return Promise.resolve();
                                            }  else {
                                            return Promise.reject("CPF Inválido");
                                            }
                                        },
                                    })
                                    ]}
                                    hasFeedback
                                >
                                    <Input />
                                </Form.Item>
                            </>
                        }

                        <Form.Item
                            label="Telefone"
                            name="telefone"
                            rules={[
                            {
                                required: true,
                                message: 'Campo obrigatório!',
                            },
                            () => ({
                                validator(rule, value) {
                                if (value && value.replace(/\D/g, '') &&
                                    isValidPhone(value)) {
                                    return Promise.resolve();
                                    }  else {
                                    return Promise.reject("Telefone Inválido");
                                    }
                                },
                            })
                            ]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>

                        {profile.cpf &&
                            <>
                                <Form.Item
                                    label="Raça / Cor"
                                    name="raca_cor"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!'
                                    }
                                    ]}
                                >
                                    <Select>
                                        <Select.Option value="amarela">Amarela</Select.Option>
                                        <Select.Option value="branca">Branca</Select.Option>
                                        <Select.Option value="indigena">Indígena</Select.Option>
                                        <Select.Option value="negra">Negra</Select.Option>
                                        <Select.Option value="parda">Parda</Select.Option>
                                        <Select.Option value="nao-declarada">Não Declarada</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Gênero"
                                    name="genero"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!'
                                    }
                                    ]}
                                >
                                    <Select>
                                        <Select.Option value="mulher">Mulher</Select.Option>
                                        <Select.Option value="homem">Homem</Select.Option>
                                        <Select.Option value="mulher-Trans">Mulher Trans</Select.Option>
                                        <Select.Option value="mulher-Travesti">Mulher Travesti</Select.Option>
                                        <Select.Option value="homem-Trans">Homem Trans</Select.Option>
                                        <Select.Option value="nao-binario">Não-Binário</Select.Option>
                                        <Select.Option value="nao-declarada">Não Declarada</Select.Option>
                                    </Select>
                                </Form.Item>
                                <p>Data de Nascimento:</p>
                                <DatePicker
                                    size='small'
                                    name="data_nascimento"
                                    value={
                                        moment(profile.data_nascimento, 'YYYY/MM/DD')
                                    }
                                    format={dateFormatList[0]} 
                                />
                            </>
                        }

                        {profile.cnpj &&
                            <>
                                <p>Data de Criação:</p>
                                <DatePicker
                                    size='small'
                                    name="data_criacao"
                                    value={
                                        moment(profile.data_criacao, 'YYYY/MM/DD')
                                    }
                                    format={dateFormatList[0]} 
                                />
                                <Form.Item
                                    label="Classificação"
                                    name="classificacao"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!'
                                    }
                                    ]}
                                >
                                    <Select type='select'>
                                        <Select.Option value="federacao">Federação</Select.Option>
                                        <Select.Option value="associacao">Associação</Select.Option>
                                        <Select.Option value="clube">Clube</Select.Option>
                                        <Select.Option value="liga">Liga</Select.Option>
                                        <Select.Option value="outros">Outros</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Número de filiados"
                                    name="num_filiados"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!'
                                    }
                                    ]}
                                >
                                    <InputNumber />
                                </Form.Item>
                            </>
                        }

                    </div>

                    <Form.Item>
                        <Button
                            className="button"
                            type="primary"
                            htmlType="submit"
                            style={{ 
                                background: "rgba(7, 128, 239, 0.8)",
                                border: 0,
                                marginLeft: "150px",
                                marginTop: '30px'
                            }}
                        >
                            Salvar alterações
                        </Button>
                    </Form.Item>
                </Form>
            </TabPane>

            <TabPane tab="Endereço" key="2">
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={
                        profile
                    }
                >

                    <div>
                        <Form.Item
                            label="CEP"
                            name="cep"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                },
                                ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label="Logradouro"
                            name="rua"
                            rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                    },
                                ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            label="Número"
                            name="numero"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                    },
                                ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Complemento"
                            name="complemento"
                            rules={[
                                {
                                    //required: true,
                                    //message: 'Campo obrigatório!',
                                    },
                                ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            label="Bairro"
                            name="bairro"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Municipio"
                            name="municipio"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div></div>
                    <Form.Item>
                        <Button
                            className="button"
                            type="primary"
                            htmlType="submit"
                            style={{ 
                                background: "rgba(7, 128, 239, 0.8)",
                                border: 0,
                                marginLeft: "150px",
                                marginTop: '30px'
                            }}
                        >
                            Salvar alterações
                        </Button>
                    </Form.Item>
                </Form>
            </TabPane>

            <TabPane tab="Acesso" key="3">
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={
                        {
                            ...profile,
                            password: ''
                        }
                    }
                >

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                type: "email",
                                required: true,
                                message: 'Campo obrigatório!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                
                    <Form.Item
                        label="Senha Atual"
                        name="oldPassword"
                    >
                        <Input.Password className='input-password' />
                    </Form.Item>

                    <Form.Item
                        label="Nova Senha"
                        name="password"
                        rules={[
                            {
                                min: 8,
                                message: 'Confirmação do password é obrigatória!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password className='input-password' />
                    </Form.Item>
                    
                    <Form.Item
                        label="Confirme sua nova senha"
                        dependencies={['password']}
                        name="confirmPassword"
                        hasFeedback
                        rules={[
                            {
                                message: 'Confirmação do password é obrigatória!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Password não confere!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password className='input-password' />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            className="button"
                            type="primary"
                            htmlType="submit"
                            style={{ 
                                background: "rgba(7, 128, 239, 0.8)",
                                border: 0,
                                marginLeft: "150px",
                                marginTop: '30px'
                            }}
                        >
                            Salvar alterações
                        </Button>
                    </Form.Item>
                </Form>
            </TabPane>

            <TabPane tab="Atividade" key="4">
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={
                        profile
                    }
                >
                    

                        {profile.cpf &&
                            <>
                                {/* <div> */}
                                <Form.Item
                                    label="Modalidade"
                                    name="modalidade"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                        type:'string',
                                        },
                                    ]}
                                >
                                {/* <Input /> */}
                                   
                                    <Select
                                        className='select-option'
                                        placeholder="Selecione sua modalidade"
                                        options={Options.optionsModality}
                                    ></Select>
                                </Form.Item>
                                <Form.Item
                                    name="profissional_edf"
                                    label='Profissional de educação física ?'
                                >
                                    <Switch defaultChecked={profile.profissional_edf} checkedChildren="Sim" unCheckedChildren="Não"  />
                                </Form.Item>

                                <Form.Item
                                    label="CREF"
                                    name="cref"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="funcao"
                                    label="Função"
                                >
                                    <Input />
                                </Form.Item>
                                
                                <Form.Item
                                    label="Recebe remuneração pela função ?"
                                    name="recebe_remuneracao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Campo obrigatório!',
                                        },
                                    ]}
                                >
                                    <Switch defaultChecked={profile.recebe_remuneracao} checkedChildren="Sim" unCheckedChildren="Não" />
                                </Form.Item>

                                <Form.Item
                                    name="valor_remuneracao"
                                    label="Valor da remuneração"
                                >
                                    <Select
                                        style={{ width: '65%' }}
                                    >
                                        <Select.Option value="ate_um_salario">Até um salário</Select.Option>
                                        <Select.Option value="ate_tres_salarios">Até três salário</Select.Option>
                                        <Select.Option value="acima_de_tres_salarios">Acima de três salário</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Possui alguma deficiência ?"
                                    name="pcd"
                                >
                                    <Switch defaultChecked={profile.pcd} checkedChildren="Sim" unCheckedChildren="Não" />
                                </Form.Item>

                                <Form.Item
                                    label="Tipo de deficiência"
                                    name="tipo_pcd"
                                >
                                    <Select
                                        style={{ width: '65%' }}
                                    >
                                        <Select.Option value="fisica">Física</Select.Option>
                                        <Select.Option value="intelectual">Intelectual</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Nível de atuação"
                                    name="nivel_atuacao"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Campo obrigatório!'
                                        }
                                    ]}
                                    >
                                    <Select
                                        style={{ width: '65%' }}
                                    >
                                        <Select.Option value="amador">Amador</Select.Option>
                                        <Select.Option value="rendimento">Rendimento</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="É filiado a federação ?"
                                    name="filiado_federacao"
                                >
                                    <Switch defaultChecked={profile.filiado_federacao} checkedChildren="Sim" unCheckedChildren="Não" />
                                </Form.Item>

                                <Form.Item
                                    label="É ou já foi beneficiado com o BOLSA-ATLETA ?"
                                    name="beneficiado_bolsa_atleta"
                                >
                                    <Switch defaultChecked={profile.beneficiado_bolsa_atleta} checkedChildren="Sim" unCheckedChildren="Não" />
                                </Form.Item>

                                <Form.Item
                                    label="Situação da bolsa"
                                    name="situacao_bolsa"
                                >
                                    <Select
                                        style={{ width: '65%' }}
                                    >
                                        <Select.Option value="ativa">Ativa</Select.Option>
                                        <Select.Option value="inativa">Inativa</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Tipo da bolsa"
                                    name="tipo_bolsa"
                                >
                                    <Select
                                        style={{ width: '65%' }}
                                    >
                                        <Select.Option value="municipal">Municipal</Select.Option>
                                        <Select.Option value="estadual">Estadual</Select.Option>
                                        <Select.Option value="federal">Federal</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Breve historico"
                                    name="breve_historico"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Campo obrigatório!'
                                        }
                                    ]}
                                >
                                    <Input.TextArea style={{ width: '65%' }} />
                                </Form.Item>
                            </>
                        }

                        {profile.cnpj &&
                            <> 
                                <Form.Item
                                    label="Modalidade"
                                    name="modalidade"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                        type: 'array',
                                        },
                                    ]}
                                >
                                    {/* <Input /> */}

                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        className='select-option'
                                        placeholder="Selecione sua modalidade"
                                        options={Options.optionsModality}
                                    >
                                    </Select>
                                </Form.Item>
                                 
                                <Form.Item
                                    label="Sede própria ?"
                                    name="sede_propria"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!'
                                      }
                                    ]}
                                    >
                                    
                                        <Switch defaultChecked={profile.sede_propria} checkedChildren="Sim" unCheckedChildren="Não" />
                                    
                                </Form.Item>

                                <Form.Item
                                    label="Desenvolve esporte para pessoas com deficiência ?"
                                    name="desenvolve_para_pcd"
                                >
                                    
                                        <Switch defaultChecked={profile.desenvolve_para_pcd} checkedChildren="Sim" unCheckedChildren="Não" />
                                   
                                </Form.Item>

                                <Form.Item
                                    label="Recebe repasse de resurso ?"
                                    name="recebe_repasse_recurso"
                                >
                                    
                                        <Switch defaultChecked={profile.recebe_repasse_recurso} checkedChildren="Sim" unCheckedChildren="Não" />
                                    
                                </Form.Item>

                                <Form.Item
                                    label="Origem do recurso"
                                    name='origem_recurso'
                                >
                                    <Select
                                        style={{ width: '65%' }}
                                    >
                                        <Select.Option value="municipal">Municipal</Select.Option>
                                        <Select.Option value="estadual">Estadual</Select.Option>
                                        <Select.Option value="federal">Federal</Select.Option>
                                        <Select.Option value="confederacao">Confederação</Select.Option>
                                        <Select.Option value="privado">Privado</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Espaço adequado ?"
                                    name="espaco_adequado"
                                >
                                    
                                        <Switch defaultChecked={profile.espaco_adequado} checkedChildren="Sim" unCheckedChildren="Não" />
                                    
                                </Form.Item>

                                <Form.Item
                                    label="Período de vigência da direção/presidência"
                                    name="vigencia_direcao"
                                >
                                    <RangePicker 
                                        defaultValue={moment('01/2021', monthFormat)} 
                                        format={monthFormat} 
                                        picker="month"
                                        placeholder=''
                                        size='small'
                                        // value={
                                        //     [moment(profile.vigencia_direcao_inicio, 'MM/YYYY'),
                                        //     moment(profile.vigencia_direcao_fim, 'MM/YYYY')]
                                        // }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Responsável Legal"
                                >
                                <Space 
                                    align="baseline"
                                    direction="vertical"
                                >
                                    <Form.Item
                                        label="Nome"
                                        name="nome_responsavel"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Campo obrigatório!'
                                        }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                        name="email_responsavel"
                                        rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message: 'Campo obrigatório!'
                                        }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Telefone"
                                        name="telefone_responsavel"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Campo obrigatório!'
                                        },
                                        () => ({
                                            validator (rule, value) {
                                            if (value && value.replace(/\D/g, '') &&
                                                isValidPhone(value)) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('Telefone Inválido')
                                            }
                                            }
                                        })
                                        ]}
                                        hasFeedback
                                    >
                                        <Input />
                                    </Form.Item>
                                </Space>
                                </Form.Item>

                                <Form.Item
                                    label="Breve histórico da Instituição"
                                    name="breve_historico"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Campo obrigatório!'
                                        }
                                    ]}
                                >
                                    <Input.TextArea style={{ width: '65%' }}  />
                                </Form.Item>
                            </>
                        }


                    

                    <Form.Item>
                        <Button
                            className="button"
                            type="primary"
                            htmlType="submit"
                            style={{ 
                                background: "rgba(7, 128, 239, 0.8)",
                                border: 0,
                                marginLeft: "150px",
                                marginTop: '30px'
                            }}
                        >
                            Salvar alterações
                        </Button>
                    </Form.Item>
                </Form>
            </TabPane>
    </Tabs>
</Container>
  )
}

export default EditProfile;
