import React from 'react';
import { Table, Space, Tag, Statistic, Row, Col, Spin, Button} from 'antd';
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import Workbook from "react-excel-workbook";
import moment from "moment";
import { DownloadOutlined } from '@ant-design/icons';
// import crypto from "crypto";

import { LoadingOutlined } from '@ant-design/icons';

import { Container } from './styles';


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ListUsersTable({ users, type, loading }) {
  
  let columns
  let Download
 

  if (type === 'pj') {
    columns = [
      {       
        title: 'Nome Instituição',
        dataIndex: 'razao_social',
        key: 'razao_social',
        render: text => <strong>{text.toUpperCase()}</strong>,
        sortDirections: ['ascend'],
        //sorter: (a, b) => a.razao_social.localeCompare(b.razao_social),
        //sortOrder: sortedInfo.columnKey === 'razao_social' && sortedInfo.order,
        sorter: (a, b) => { return a.razao_social.localeCompare(b.razao_social)},
      },
      {
        title: 'Modalidade',
        dataIndex: 'modalidade',
        key: 'modalidade',
        
      },
      {
        title: 'CNPJ',
        dataIndex: 'cnpj',
        key: 'cnpj',
        //sortDirections:['ascend', 'descend'],
        sorter: (a, b) => a.cnpj - b.cnpj,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => (
          <>
            {status === 'em_analise' && 
              <Tag color='geekblue' >
                <small>Em análise</small>
              </Tag>
            }
            {status === 'valido' && 
              <Tag color='green' >
                <small>Válido</small>
              </Tag>
            }
            {status === 'invalido' && 
              <Tag color='red' >
                <small>Inválido</small>
              </Tag>
            }
          </>
        ),
        
        filters: [
          {
            text: 'Em Análise',
            value: 'em_analise',
          },
          {
            text: 'Válido',
            value: 'valido',
          },
          {
            text: 'Inválido',
            value: 'invalido',
          },
          
        ],
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.status.indexOf(value) === 0,
      },
      {
        title: 'Abrir',
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <Space size="middle">
            <Link to={`user-pj/${id}`}>
              <FiEdit />
            </Link>
          </Space>
        ),
      },
    ];

    //teste = Array.from(users.modalidade);
    //const reducedArray = users.modalidade.reduce((acc, curr) => `${acc}${curr.lat},${curr.lon}|` ,'')
    //arrayModalidade = teste.map((n) =>{return n+","});
    //console.log(arrayModalidade);
    

    Download = (
      <Workbook
        filename="Cadastros_PJ_CAE.xlsx"
        element={<Button type="primary" icon={<DownloadOutlined />} loading={loading}>Download</Button>}
      >
        <Workbook.Sheet data={users} name="Cadastros_PJ">
          {/* <Workbook.Column label="Id" value={(row) => row.id} /> */}
          <Workbook.Column label="STATUS" value={(row) => row.status} />
          <Workbook.Column label="CNPJ" value={(row) => row.cnpj ? row.cnpj : ''} />
          <Workbook.Column label="NOME INSTITUICAO" value={(row) => row.razao_social ? row.razao_social : ''} />
          <Workbook.Column label="EMAIL" value={(row) => row.email ? row.email : ''} />
          <Workbook.Column label="TELEFONE" value={(row) => row.telefone ? row.telefone : ''} />
          <Workbook.Column label="DATA DE CRIACAO" value={(row) => row.data_criacao ? moment(row.data_criacao).utc("America/Alagoas").format("DD/MM/YYYY") : ''} />
          <Workbook.Column label="CLASSIFICACAO" value={(row) => row.classificacao ? row.classificacao : ''} />
          <Workbook.Column label="NUM DE FILIADOS" value={(row) => row.num_filiados ? row.num_filiados : ''} />
          <Workbook.Column label="CEP" value={(row) => row.cep ? row.cep : ''} />
          <Workbook.Column label="RUA" value={(row) => row.rua ? row.rua : ''} />
          <Workbook.Column label="NUMERO" value={(row) => row.numero ? row.numero : ''} />
          <Workbook.Column label="COMPLEMENTO" value={(row) => row.complemento ? row.complemento : ''} />
          <Workbook.Column label="BAIRRO" value={(row) => row.bairro ? row.bairro : ''} />
          <Workbook.Column label="MUNICIPIO" value={(row) => row.municipio ? row.municipio : ''} />
          <Workbook.Column label="MODALIDADE" value={(row) => row.modalidade ? row.modalidade[0] : ''} />
          {/*<Workbook.Column label="MODALIDADE" value={(row) =>row.modalidade ? row.Array.from(row.modalidade).map((n)=>{return n+","}) : ''} />*/}
          <Workbook.Column label="SEDE PROPRIA" value={(row) => row.sede_propria ? 'Sim' : 'Não'} />
          <Workbook.Column label="DESENVOLVE PARA PCD" value={(row) => row.desenvolve_para_pcd ? 'Sim' : 'Não'} />
          <Workbook.Column label="RECEBE REPASSE DE RECURSOS" value={(row) => row.recebe_repasse_recurso ? 'Sim' : 'Não'} />
          <Workbook.Column label="ORIGEM DO RECURSO" value={(row) => row.origem_recurso ? row.origem_recurso : ''} />
          <Workbook.Column label="ESPACO ADEQUADO" value={(row) => row.espaco_adequado ? 'Sim' : 'Não'} />
          <Workbook.Column label="NOME DO RESPONSAVEL" value={(row) => row.nome_responsavel ? row.nome_responsavel : ''} />
          <Workbook.Column label="EMAIL DO RESPONSAVEL" value={(row) => row.email_responsavel ? row.email_responsavel : ''} />
          <Workbook.Column label="TELEFONE DO RESPONSAVEL" value={(row) => row.telefone_responsavel ? row.telefone_responsavel : ''} />
          <Workbook.Column label="VIGENCIA DA DIRECAO/PRESIDENCIA" value={(row) => row.vigencia_direcao_inicio ? 
            `${moment(row.vigencia_direcao_inicio).utc("America/Alagoas").format("MM/YYYY")} - ${moment(row.vigencia_direcao_fim).utc("America/Alagoas").format("MM/YYYY")}`
            : ''} />
          <Workbook.Column label="BREVE HISTORICO DA INSTITUICAO" value={(row) => row.breve_historico ? row.breve_historico : ''} />
        </Workbook.Sheet>
      </Workbook>
    );

  } else if (type === 'pf') {
    columns = [
      {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
        render: text => <strong>{text.toUpperCase()}</strong>,
        //defaultSortOrder: 'ascend',
        sortDirections: ['ascend'],
        sorter: (a, b) => { return a.nome.localeCompare(b.nome)},
              
      },
      {
        title: 'Modalidade',
        dataIndex: 'modalidade',
        key: 'modalidade',
        sortDirections: ['ascend'],
        sorter: (a, b) => a.modalidade.localeCompare(b.modalidade),
              
      },
      {
        title: 'CPF',
        dataIndex: 'cpf',
        key: 'CPF',
        sorter: (a, b) => a.cpf - b.cpf,
        
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => (
          <>
            {status === 'em_analise' && 
              <Tag color='geekblue' >
                <small>Em análise</small>
              </Tag>
            }
            {status === 'valido' && 
              <Tag color='green' >
                <small>Válido</small>
              </Tag>
            }
            {status === 'invalido' && 
              <Tag color='red' >
                <small>Inválido</small>
              </Tag>
            }
          </>
        ),
        filters: [
          {
            text: 'Em Análise',
            value: 'em_analise',
          },
          {
            text: 'Válido',
            value: 'valido',
          },
          {
            text: 'Inválido',
            value: 'invalido',
          },
          
        ],
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.status.indexOf(value) === 0,
      },
      {
        title: 'Abrir',
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <Space size="middle">
            <Link to={{
              pathname: `user-pf/${id}`
            }}>
              <FiEdit />
            </Link>
          </Space>
        ),
      },
    ];

    Download = (
      <Workbook
        filename="Cadastros_PF_CAE.xlsx"
        element={<Button type="primary" icon={<DownloadOutlined />} loading={loading}>Download</Button>}
      >
        <Workbook.Sheet data={users} name="Cadastros_PF">
          {/* <Workbook.Column label="Id" value={(row) => row.id} /> */}
          <Workbook.Column label="STATUS" value={(row) => row.status} />
          <Workbook.Column label="CPF" value={(row) => row.cpf ? row.cpf : ''} />
          <Workbook.Column label="NOME" value={(row) => row.nome ? row.nome : ''} />
          <Workbook.Column label="EMAIL" value={(row) => row.email ? row.email : ''} />
          <Workbook.Column label="TELEFONE" value={(row) => row.telefone ? row.telefone : ''} />
          <Workbook.Column label="DATA DE NASCIMENTO" value={(row) => row.data_nascimento ? moment(row.data_nascimento).utc("America/Alagoas").format("DD/MM/YYYY") : ''} />
          <Workbook.Column label="RACA/COR" value={(row) => row.raca_cor ? row.raca_cor : ''} />
          <Workbook.Column label="GENERO" value={(row) => row.genero ? row.genero : ''} />
          <Workbook.Column label="CEP" value={(row) => row.cep ? row.cep : ''} />
          <Workbook.Column label="RUA" value={(row) => row.rua ? row.rua : ''} />
          <Workbook.Column label="NUMERO" value={(row) => row.numero ? row.numero : ''} />
          <Workbook.Column label="COMPLEMENTO" value={(row) => row.complemento ? row.complemento : ''} />
          <Workbook.Column label="BAIRRO" value={(row) => row.bairro ? row.bairro : ''} />
          <Workbook.Column label="MUNICIPIO" value={(row) => row.municipio ? row.municipio : ''} />
          <Workbook.Column label="MODALIDADE" value={(row) => row.modalidade ? row.modalidade : ''} />
          <Workbook.Column label="FUNCAO" value={(row) => row.funcao ? row.funcao : ''} />
          <Workbook.Column label="RECEBE REMUNERACAO" value={(row) => row.recebe_remuneracao ? 'Sim' : 'Não'} />
          <Workbook.Column label="VALOR DA REMUNERACAO" value={(row) => row.valor_remuneracao ? row.valor_remuneracao : ''} />
          <Workbook.Column label="PCD" value={(row) => row.pcd ? 'Sim' : 'Não'} />
          <Workbook.Column label="TIPO DE DEFICIENCIA" value={(row) => row.tipo_pcd ? row.tipo_pcd : ''} />
          <Workbook.Column label="NIVEL DE ATUACAO" value={(row) => row.nivel_atuacao ? row.nivel_atuacao : ''} />
          <Workbook.Column label="FILIADO A FEDERACAO" value={(row) => row.filiado_federacao ? 'Sim' : 'Não'} />
          <Workbook.Column label="BENEFICIARIO BOLSA ATLETA" value={(row) => row.beneficiado_bolsa_atleta ? 'Sim' : 'Não'} />
          <Workbook.Column label="SITUACAO DA BOLSA" value={(row) => row.situacao_bolsa ? row.situacao_bolsa : ''} />
          <Workbook.Column label="TIPO DA BOLSA" value={(row) => row.tipo_bolsa ? row.tipo_bolsa : ''} />
          <Workbook.Column label="BREVE HISTORICO" value={(row) => row.breve_historico ? row.breve_historico : ''} />
        </Workbook.Sheet>
      </Workbook>
    );
  }

  return (
    <Container>
      <header>
        <Row gutter={40}>
          <Col span={30}>
          { users.length === 0 ? 
            <Spin indicator={antIcon} /> 
            :
            <Statistic title="Usuários Cadastradas" value={users.length} /> 
          }
          </Col>
          
          {Download}
          
        </Row>
      </header>
      <Table showSorterTooltip={{ title: 'Clique para ordenar' }} bordered columns={columns} dataSource={users.reverse()} pagination={{ pageSize: 10 }}/>
      
    </Container>
  );
}

export default ListUsersTable;


