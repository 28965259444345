import React from "react";
import { useSelector } from "react-redux";
import EditProfile from "../../components/EditProfile";
import EditProfileSelaj from "../../components/EditProfileSelaj";

function EditingProfile() {
  const { profile } = useSelector((state) => state.user);

  return (
    <>
      { profile.role ? <EditProfileSelaj /> : <EditProfile /> }
    </>
  )
}

export default EditingProfile;
