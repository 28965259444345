import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FiLogIn } from 'react-icons/fi'
import { Form, Input, Button } from 'antd';

import logo from '../../assets/Logo-CAE.png'
import logoSecti from '../../assets/Marca-Governo-de-Alagoas_White_SECTI.png'
import logoSelaj from '../../assets/logo-SELAJ.png'

import { signInRequest } from '../../store/modules/auth/actions'

import { Container, Content } from './styles'

function SignIn () {
  const dispatch = useDispatch()

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 25,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  async function handleSubmit ({ email, password }) {
    dispatch(signInRequest({ email, password }));
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Container>
      <Content>
        <img src={logo} alt="CAE" />

        <h1>Acesse o Sistema</h1>

          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: 'Email é obrigatório!',
                },
              ]}
            >
              <Input placeholder="Seu email cadastrado" />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira sua senha!',
                },
              ]}
            >
              <Input.Password placeholder="Sua senha" />
            </Form.Item>
            <div 
              style={{ marginTop: "-22px", marginBottom: "20px" }}
            >
              <Link to="/novasenha" type="button" style={{fontSize: "1rem", color: "rgba(236, 92, 40, 0.8)", }}>
                  Esqueci minha senha
              </Link>
            </div>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Entrar
              </Button>
            </Form.Item>
          </Form>

        <Link to="/cadastro" type="button">
          <FiLogIn />
          Faça seu cadastro
        </Link>

        <div id="gov">
          <a href='http://www.cienciaetecnologia.al.gov.br/' target="_blank" rel="noopener noreferrer">
            <img src={logoSecti} alt="SECTI-AL" />
          </a>
          <a href='http://selaj.com.br/' target="_blank" rel="noopener noreferrer">
            <img src={logoSelaj} alt="SELAJ-AL" id='selaj' />
          </a>
        </div>      
      </Content>

    </Container>
  )
}

export default SignIn
