import React from 'react'
import { Link } from 'react-router-dom'

import { FiLogIn } from 'react-icons/fi'

import FormPf from '../../components/FormPf'
import FormPj from '../../components/FormPj'

import { Tabs } from 'antd'

import logo from '../../assets/Logo-CAE.png'

import { Container } from './styles'

function SignUp () {
  const { TabPane } = Tabs

  return (
    <Container>
      <img src={logo} alt="CADCTI" />

      <h1>Cadastro</h1>

      <div>
        <Tabs defaultActiveKey="1" centered >
          <TabPane tab="Pessoa Física" key="1">
            {/* Content of Tab Pane 1 */}
            <FormPf />
          </TabPane>
          <TabPane tab="Pessoa Jurídica" key="2">
            {/* Content of Tab Pane 2 */}
            <FormPj />
          </TabPane>
        </Tabs>
      </div>

      <Link to="/login" type="button">
        <FiLogIn />
        Já tenho login
      </Link>
    </Container>
  )
}

export default SignUp
