import React from "react";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { toast } from "react-toastify";
import { Input, Button, Form } from 'antd';

import history from "../../services/history";
import api from "../../services/api";

import logo from '../../assets/Logo-CAE.png'
import logoSecti from '../../assets/Marca-Governo-de-Alagoas_White_SECTI.png'
import logoSelaj from '../../assets/logo-SELAJ.png'


import { Container, Content } from './styles';

function ForgotPasword() {

  async function handleSubmit(email) {
      try {
        const response = await api.patch("forgotpassword", email);

        toast.success(`${response.data.message}`);

        history.push("/login", response.data);
      } catch (error) {
        toast.error(`${error.response.data.error}`);
      };
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
    
  return (
    <Container>
      <Content>
        <img src={logo} alt="CAE" />

         <h1>Recuperar acesso ao sistema</h1>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: 'Email é obrigatório!',
                },
              ]}
            >
              <Input placeholder="Seu email cadastrado" />
            </Form.Item>
            
            <Button htmlType="submit">
              Receber nova senha
            </Button>
          </Form>

          <Link to="/login" type="button">
            <FiLogIn />
              Voltar
          </Link>

          <div id="gov">
            <a href='http://www.cienciaetecnologia.al.gov.br/' target="_blank" rel="noopener noreferrer">
              <img src={logoSecti} alt="SECTI-AL" />
            </a>
            <a href='http://selaj.com.br/' target="_blank" rel="noopener noreferrer">
              <img src={logoSelaj} alt="SELAJ-AL" id='selaj' />
            </a>
          </div>      
        </Content>
    </Container>
  );
}

export default ForgotPasword;
