import styled from 'styled-components'

export const Container = styled.div`
    
    width: 44vw; 

 form {
    padding: 10px;
    
    .ant-upload {
          
        button {
            width: 40vw;
        }
    }
      

    button {
        border: 0;
        float: right;

    }

 } 

 .spin {
     display: flex;
     justify-content: center;
     padding: 20px 0;
 }

    @media (max-width: 700px) {
        form {
            .ant-upload {
          
                button {
                    width: 85vw;
                }
            }
        }
        
    }
 
`
