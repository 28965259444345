import React, { useEffect, useState } from 'react';
import { FaIdCardAlt } from "react-icons/fa";
import { 
  Popconfirm, 
  Button, 
  Descriptions, 
  Card, 
  Empty,
  Tabs,
  Breadcrumb,
  Skeleton,
  Modal,
  Form,
  Select,
  message
} from 'antd';
import { 
  FolderOpenFilled,
  FilePdfFilled,
  HomeOutlined
} from '@ant-design/icons';
import moment from "moment";
import { FaTrash } from "react-icons/fa"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Container, Header } from './styles';

import api from "../../services/api";
import history from "../../services/history";

function ProfileUserPj({ match }) {
    const { TabPane } = Tabs;
    const { Option } = Select;

    const { id } = match.params;

    const [profile, setProfile] = useState();
    const [visible, setVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 16,
        },
      };

    useEffect(() => {
        api.get(`usuarios/profile-pj/?id=${id}`).then((response) => {
          setProfile(response.data);
        });
    }, [id]);
    
    const showPopconfirm = () => {
        setVisible(true);
    };

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleCancelValidate = () => {
        setIsModalVisible(false);
      };

    const handleDelet = () => {
        setConfirmLoading(true);
        setTimeout(async () => {
          await api.delete(`/usuarios/profile-pj/?id=${id}`).then((response) => {
            setVisible(false);
            setConfirmLoading(false);
    
            history.push("/");
            toast.success(response.data.message);
            
          });
          
        }, 2000);
    };

    const onFinish = async (values) => {
        await api.patch(`/usuarios/profile-pj/?id=${id}`, values).then((response) => {
            setIsModalVisible(false);
            window.location.reload();
            message.success(response.data.message);
        }).catch((err) => {
            message.err(err.response.data.error);
        });
    
    };

    const handleCancel = () => {
        setVisible(false);
    };

  return (
      <Container>
            <div>
                <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to={{ pathname: "/" }}>
                        <HomeOutlined />
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {profile?.razao_social ? profile?.razao_social : < Skeleton />}
                </Breadcrumb.Item>
                </Breadcrumb>
            </div>

          <Header>
            <Popconfirm
                title="Excluir Cadastro ?"
                visible={visible}
                onConfirm={handleDelet}
                okButtonProps={{ loading: confirmLoading }}
                onCancel={handleCancel}
            >
            <Button danger type="primary" onClick={showPopconfirm}>
                <FaTrash />
            </Button>
            </Popconfirm>
        </Header>

        <div>
            <Descriptions
                bordered
                size="small"
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                layout="vertical"
                style={{
                    margin: "10px"
                }}
                title={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <strong>
                            {/* {profile.razao_social || profile.nome || ''} */}
                            <small>Última atualização - {moment(profile?.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
                        </strong>
                        <div id="validacao">
                            <Button type="primary" onClick={showModal}>
                                Validar Usuário
                            </Button>
                            <Modal
                                className="modal"
                                title="Validação de Perfil"
                                visible={isModalVisible}
                                width={300}
                                onCancel={handleCancelValidate}
                                footer={ 
                                <Button onClick={handleCancelValidate} danger>Cancelar</Button>
                                }
                            >
                                <Form
                                {...layout}
                                name="basic"
                                initialValues={profile}
                                onFinish={onFinish}
                                >
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    rules={[{ required: true, message: 'Campo Obrigatório!' }]}        
                                >
                                    <Select placeholder="Status">
                                        <Option value="em_analise">Em análise</Option>
                                        <Option value="valido">Válido</Option>
                                        <Option value="invalido">Inválido</Option>
                                    </Select>
                                </Form.Item>
                                

                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                    Submit
                                    </Button>
                                </Form.Item>
                                </Form>
                            </Modal>
                        </div>
                    </div>
                }
            >
                <Descriptions.Item
                    span={3}
                    style={{
                        textAlign: "center"
                    }}
                    >
                    <FaIdCardAlt size={60} color={"rgba(0, 180, 255, 0.9)"} />
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                            style={{
                                color: "rgba(0, 180, 255, 0.9)"
                            }}
                        >
                            Nome da Instituição:
                        </strong>
                    }
                >
                    <small
                        id='formatCapitalize'
                    >
                        {/* { profile.cnpj.replaceAll(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5") || profile.cpf.replaceAll(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')} */}
                        {profile?.razao_social}
                    </small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                            CNPJ:
                        </strong>
                    }
                >
                    <small>
                        {/* { profile.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5") || profile.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')} */}
                        {profile?.cnpj}
                    </small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Telefone:
                        </strong>
                    }
                    >
                    <small>
                        {profile?.telefone.replace(/^(\d\d)(\d{4})(\d)/,"($1) $2-$3")}
                        {/* {profile.telefone} */}
                    </small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Email:
                        </strong>
                    }
                    >
                    <small>
                        {profile?.email}
                    </small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                            Data de criação:
                        </strong>
                    }
                    >
                    <small>{moment(profile?.data_criacao).utc("America/Alagoas").format("DD/MM/YYYY")}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Modalidade:
                        </strong>
                    }
                    >
                    <small>{profile?.modalidade}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                    <strong
                        style={{
                        color: "rgba(0, 180, 255, 0.9)"
                        }}
                    >
                        Classificação:
                    </strong>
                    }
                >
                    <small>{!profile?.classificacao && ''}</small>
                    <small>{profile?.classificacao === 'federacao' && 'Federação'}</small>
                    <small>{profile?.classificacao === 'associacao' && 'Associação'}</small>
                    <small>{profile?.classificacao === 'clube' && 'Clube'}</small>
                    <small>{profile?.classificacao === 'liga' && 'Liga'}</small>
                    <small>{profile?.classificacao === 'outros' && 'Outros'}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                    <strong
                        style={{
                        color: "rgba(0, 180, 255, 0.9)"
                        }}
                    >
                        Número de filiados:
                    </strong>
                    }
                >
                    <small>{profile?.num_filiados}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Status:
                        </strong>
                    }
                >
                    <strong>{profile?.status === 'em_analise' && 'Em análise'}</strong>
                    <strong>{profile?.status === 'em_validacao' && 'Em validação'}</strong>
                    <strong>{profile?.status === 'valido' && 'Em Válido'}</strong>
                    <strong>{profile?.status === 'invalido' && 'Inválido'}</strong>
                </Descriptions.Item>

            </Descriptions>

            <Descriptions
                bordered
                size="small"
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                layout="vertical"
                style={{
                margin: "10px"
                }}
                title={
                <div className="title">
                    <strong>
                    <small>Endereço</small>
                    </strong>
                </div>
                }
            >
                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Logradouro:
                        </strong>
                    }
                >
                    <small>{profile?.rua}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Número:
                        </strong>
                    }
                >
                    <small>{profile?.numero}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Bairro:
                        </strong>
                    }
                >
                    <small>{profile?.bairro}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Complemento:
                        </strong>
                    }
                >
                    <small>{profile?.complemento ? profile?.complemento : '-'}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        CEP:
                        </strong>
                    }
                >
                    <small>{profile?.cep}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                        }}
                        >
                        Município:
                        </strong>
                    }
                >
                    <small>{profile?.municipio}</small>
                </Descriptions.Item>
                
            </Descriptions>

            <Descriptions
                bordered
                size="small"
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                layout="vertical"
                style={{
                    margin: "10px"
                }}
                title={
                    <div className="title">
                    <strong>
                        <small>Atividade</small>
                    </strong>
                    </div>
                }
            >
                <Descriptions.Item 
                    label={
                    <strong
                        style={{
                        color: "rgba(0, 180, 255, 0.9)"
                    }}
                >
                    Sede propria ?
                    </strong>
                    }
                >
                    <small>{profile?.sede_propria ? 'Sim' : 'Não'}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                            style={{
                                color: "rgba(0, 180, 255, 0.9)"
                            }}
                        >
                            Possui espaço adequado para treinamento da modalidade ?
                        </strong>
                    }
                >
                    <small>{profile?.espaco_adequado ? 'Sim' : 'Não'}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                            style={{
                                color: "rgba(0, 180, 255, 0.9)"
                            }}
                        >
                            Desenvolve esporte para pessoas com deficiência ?
                        </strong>
                    }
                >
                    <small>{profile?.desenvolve_para_pcd ? 'Sim' : 'Não'}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                            style={{
                                color: "rgba(0, 180, 255, 0.9)"
                            }}
                        >
                            Recebe repasse de recurso ?
                        </strong>
                    }
                >
                    <small>{profile?.recebe_repasse_recurso ? 'Sim' : 'Não'}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                            style={{
                                color: "rgba(0, 180, 255, 0.9)"
                            }}
                        >
                            Origem do recurso ?
                        </strong>
                    }
                >
                    <small>{!profile?.origem_recurso && '-'}</small>
                    <small>{profile?.origem_recurso === 'municipal' && 'Municipal'}</small>
                    <small>{profile?.origem_recurso === 'estadual' && 'Estadual'}</small>
                    <small>{profile?.origem_recurso === 'federal' && 'Federal'}</small>
                    <small>{profile?.origem_recurso === 'confederacao' && 'Confederação'}</small>
                    <small>{profile?.origem_recurso === 'privado' && 'Privado'}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                        <strong
                        style={{
                            color: "rgba(0, 180, 255, 0.9)"
                    }}
                    >
                        Período de vigência da direção/presidência
                        </strong>
                    }
                    >
                    <small>{profile?.vigencia_direcao_inicio && moment(profile?.vigencia_direcao_inicio).utc("America/Alagoas").format("MM/YYYY")} </small> - {' '} 
                    <small>{profile?.vigencia_direcao_fim &&  moment(profile?.vigencia_direcao_fim).utc("America/Alagoas").format("MM/YYYY")} </small>
                </Descriptions.Item>

                <Descriptions.Item 
                    span={3}
                    label={
                        <strong
                            style={{
                                color: "rgba(0, 180, 255, 0.9)"
                            }}
                        >
                            Breve histórico da Instituição
                        </strong>
                    }
                >
                    <small>{profile?.breve_historico}</small>
                </Descriptions.Item>

            </Descriptions>

            <Descriptions
              bordered
              size="small"
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              layout="vertical"
              style={{
                margin: "10px"
              }}
              title={
                <div className="title">
                  <strong>
                    <small>Responsável Legal</small>
                  </strong>
                </div>
              }
            >
                <Descriptions.Item 
                    span={3}
                    label={
                    <strong
                        style={{
                        color: "rgba(0, 180, 255, 0.9)"
                        }}
                    >
                        Nome:
                    </strong>
                    }
                >
                    <small>{profile?.nome_responsavel}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                    <strong
                        style={{
                        color: "rgba(0, 180, 255, 0.9)"
                        }}
                    >
                        Telefone:
                    </strong>
                    }
                >
                    <small>{profile?.telefone_responsavel}</small>
                </Descriptions.Item>

                <Descriptions.Item 
                    label={
                    <strong
                        style={{
                        color: "rgba(0, 180, 255, 0.9)"
                        }}
                    >
                        Email:
                    </strong>
                    }
                >
                    <small>{profile?.email_responsavel}</small>
                </Descriptions.Item>

            </Descriptions>

        </div>

        <Tabs 
            defaultActiveKey="1" 
            type="card"
            size="small"
            style={{
            padding: "10px"
            }}
        >
            {/* Arquivos */}            
            <TabPane 
                tab="Arquivos" 
                key="1" 
                style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    flexWrap: 'wrap' 
                }}
            >

                {/* {profile?.files?.cpf ? */}
                {profile?.files?.cnpj ?
                    <Card
                        style={{
                            width: "50vw",
                            marginTop: 16,
                            textAlign: "center" 
                        }}
                        title='CNPJ'
                        actions={[
                        <a href={`${profile?.files?.cnpj.url}`} target="_blank" rel="noopener noreferrer">
                            <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                        </a>
                        ]}
                    >
                        <FilePdfFilled style={{ fontSize: "2rem" }} />
                        {profile.files?.cnpj?.name}
                    </Card>
                :
                  <Empty
                    description="Sem CNPJ"
                    style={{
                      width: "50vw",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                  />
                }

                {profile?.files?.ata_eleicao_posse_atual_diretoria ?
                    <Card
                        style={{
                            width: "50vw",
                            marginTop: 16,
                            textAlign: "center" 
                        }}
                        title='Ata de eleição e posse da atual diretoria'
                        actions={[
                        <a href={`${profile?.files?.ata_eleicao_posse_atual_diretoria.url}`} target="_blank" rel="noopener noreferrer">
                            <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                        </a>
                        ]}
                    >
                        <FilePdfFilled style={{ fontSize: "2rem" }} />
                        {profile.files?.ata_eleicao_posse_atual_diretoria?.name}
                    </Card>
                :
                  <Empty
                    description="Sem Ata de eleição e posse da atual diretoria"
                    style={{
                      width: "50vw",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                  />
                }
                {profile?.files?.calendario_esportivo ?
                    <Card
                        style={{
                            width: "50vw",
                            marginTop: 16,
                            textAlign: "center" 
                        }}
                        title='Calendario Esportivo'
                        actions={[
                        <a href={`${profile?.files?.calendario_esportivo.url}`} target="_blank" rel="noopener noreferrer">
                            <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                        </a>
                        ]}
                    >
                        <FilePdfFilled style={{ fontSize: "2rem" }} />
                        {profile?.files?.calendario_esportivo.name}
                    </Card>
                :
                  <Empty
                    description='Sem Calendario Esportivo'
                    style={{
                      width: "50vw",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                  />
                }
                {profile?.files?.estatuto ?
                    <Card
                        style={{
                            width: "50vw",
                            marginTop: 16,
                            textAlign: "center" 
                        }}
                        title='Estatuto'
                        actions={[
                        <a href={`${profile?.files?.estatuto.url}`} target="_blank" rel="noopener noreferrer">
                            <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                        </a>
                        ]}
                    >
                        <FilePdfFilled style={{ fontSize: "2rem" }} />
                        {profile?.files?.estatuto.name}
                    </Card>
                :
                  <Empty
                    description='Sem Estatuto'
                    style={{
                      width: "50vw",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                  />
                }
                {/* {profile?.files?.comprovante_bancario ? 
                    <Card
                        style={{
                            width: "50vw",
                            marginTop: 16,
                            textAlign: "center" 
                        }}
                        title='Comprovante bancário'
                        actions={[
                        <a href={`${profile?.files?.comprovante_bancario.url}`} target="_blank" rel="noopener noreferrer">
                            <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                        </a>
                        ]}
                    >
                        <FilePdfFilled style={{ fontSize: "2rem" }} />
                        {profile?.files?.comprovante_bancario.name}
                    </Card>
                :
                  <Empty
                    description='Sem Comprovante bancário'
                    style={{
                      width: "50vw",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                  />
                } */}

            </TabPane>

        </Tabs>
      </Container>
  )
}

export default ProfileUserPj;