import React from "react";
import PropTypes from "prop-types";

import Sidebar from "../../../components/Sidebar";

import { Content, Wrapper, Background } from "./styles";

export default function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <Content>
        <Sidebar />
      </Content>
      <Background>{children}</Background>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
