import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 21.5vw;
  position: fixed;
  background: linear-gradient(
    -10deg,
    rgba(7, 128, 239, 0.5),
    rgba(0, 120, 200, 0.1)
  );

  -moz-box-shadow: 3px 3px 5px 1px #ccc;
  -webkit-box-shadow: 3px 3px 5px 1px #ccc;
  box-shadow: 3px 3px 5px 1px #ccc;

  @media print {
    display: none;
  }
`;

export const Content = styled.div`
  padding: 0 8px;

  nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    img {
      width: 30vw;
    }

    h1 {
      margin-top: 5px;
      font-size: 1.6rem;
      font-weight: 700;
      text-transform: capitalize;
      color: rgba(0, 80, 200, 1);

      /* ::first-letter {
        text-transform:capitalize; // Primeira letra up
      }  */
    }

    h2 {
      font-size: 1.3rem;
      font-weight: 300;
      text-transform: uppercase;
      color: rgba(0, 80, 200, 1);

       /* ::first-letter {
        text-transform:capitalize; // Primeira letra up
      }  */
    }

    h3 {
      font-size: 1rem;
      font-weight: 300;
      color: rgba(0, 80, 200, 1);

    }
  }

  div {
    display: flex;
    justify-content: center;
    margin-top: 8vh;

    button {
      padding: 5px;
      height: 5vh;
      width: 10vw;
      background: #d32935;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 1.2rem;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.09, "#d32935")};
      }

      strong {
        margin: 5px;
      }
    }
  }

  @media (max-width: 700px) {
    div {
      button {

        strong {
          display: none;
        }
      }
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  a {
    text-decoration: none;
    margin-bottom: 8px;
    color: rgba(0, 80, 200, 1);
    border-radius: 5px;

    &:hover {
      background: rgba(0, 120, 200, 0.2);
    }
  }
`;

export const Footer = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  align-content: center;


  div {
    img {
      width: 150px;
      height: 50px;
      /* margin-bottom: 10px; */
    }
    
  }

  p {
    font-size: 1.2rem;
  }

  @media (max-width: 700px) {

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 110px;
        height: 50px;
      }
    }
  }
`;
