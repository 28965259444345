import styled from 'styled-components'
import { shade } from 'polished'

export const Container = styled.div`
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 28vw;
  }

  h1 {
    font-size: 1.9rem;
    margin: 10px;
    font-weight: 500;
    color: rgba(0, 60, 170, 0.9)
  }

  > a {
    color: #5271ff;
    font-size: 1.6rem;
    opacity: 0.8;
    text-decoration: none;
    transition: background 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }

    &:hover {
      color: ${shade(0.05, '#5271ff')};
    }
  }

  @media (max-width: 700px) {
    /* padding: 15px; */
    /* width: 25vw; */

    img {
      width: 50vw;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90vw;
    }
  }
`
