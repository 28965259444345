import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FaIdCardAlt } from "react-icons/fa";
import { 
  Tabs,
  Descriptions,
  Card,
  Button,
  Form,
  Upload
} from "antd";

import { 
  FilePdfFilled,
  DeleteFilled,
  FolderOpenFilled,
  UploadOutlined
} from '@ant-design/icons';

import moment from "moment";

import { 
  beforeUploadFile, 
  customUploadFile, 
  handleDelFile 
 } from '../../helpers/uploadFileFirebase'

import { updateProfileRequest } from "../../store/modules/user/actions"

import { Container, InfoEmpresa } from "./styles";

function DashUsers() {
  const dispatch = useDispatch();

  const { TabPane } = Tabs;
  // const { Meta } = Card;

  //  Files PF
  const [cpf, setCpf] = useState(null)
  const [clipping, setClipping] = useState(null)

  // Files PJ
  const [ataEleicaoAtualDiretoria, setAtaEleicaoAtualDiretoria] = useState(null)
  const [calendarioEsportivo, setCalendarioEsportivo] = useState(null)
  const [cnpj, setCnpj] = useState(null)
  const [estatuto, setEstatuto] = useState(null)
  const [identidadePresidente, setIdentidadePresidente] = useState(null)
  const [enderecoPresidente, setEnderecoPresidente] = useState(null)

  const layoutLast = {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 14
    }
  }

  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    // let receivedFile = []
    const { files: filesProfile } = profile
    Object.keys(filesProfile).forEach(element => {
      switch (filesProfile[element]?.filename) {
        case 'cpf':
          setCpf(filesProfile[element])
          break;
        case 'clipping':
          setClipping(filesProfile[element])
          break;
        case 'calendario_esportivo':
          setCalendarioEsportivo(filesProfile[element])
          break;
        case 'ata_eleicao_posse_atual_diretoria':
          setAtaEleicaoAtualDiretoria(filesProfile[element])
          break;
        case 'estatuto':
          setEstatuto(filesProfile[element])
          break;
        case 'cnpj':
          setCnpj(filesProfile[element])
          break;
        case 'identidade_presidente':
          setIdentidadePresidente(filesProfile[element])
          break;
        case 'endereco_presidente':
          setEnderecoPresidente(filesProfile[element])
          break;
        default:
          break;
      }
    })

    // setFiles(receivedFile)
    
  }, [profile]);

  const handleEditFiles = async () => {

    const filesPf = {
      cpf,
      clipping
    }

    const filesPj = {
      cnpj,
      calendario_esportivo: calendarioEsportivo,
      ata_eleicao_posse_atual_diretoria: ataEleicaoAtualDiretoria,
      estatuto
    }

    const files = profile.cnpj ? { ...filesPj } : { ...filesPf }

    if (!cpf) {
      delete files.cpf
    } else if (!clipping) {
      delete files.clipping
    } else if (!calendarioEsportivo) {
      delete files.calendario_esportivo
    } else if (!ataEleicaoAtualDiretoria) {
      delete files.ata_eleicao_posse_atual_diretoria
    } else if (!estatuto) {
      delete files.estatuto
    } else if (!identidadePresidente) {
      delete files.identidade_presidente
    } else if (!enderecoPresidente) {
      delete files.endereco_presidente
    }

    await dispatch(updateProfileRequest({ files, type: profile.cnpj ? 'pj' : 'pf' }))
  };

  const handleConfirmDelFile = async (data) => {
    const edit = {
      name: data.name,
      hash: data.hash,
      filename: data.filename
    }

    await handleDelFile({ edit })

    const filesPf = {
      cpf,
      clipping
    }

    const filesPj = {
      cnpj,
      calendario_esportivo: calendarioEsportivo,
      ata_eleicao_posse_atual_diretoria: ataEleicaoAtualDiretoria,
      estatuto,
      identidade_presidente: identidadePresidente,
      endereco_presidente: enderecoPresidente
    }

    const files = profile.cnpj ? { ...filesPj } : { ...filesPf }

    switch (edit.filename) {
      case 'cpf':
        setCpf(null)
        files.cpf = null
        break;
      case 'clipping':
        setClipping(null)
        files.clipping = null
        break;
      case 'calendario_esportivo':
        setCalendarioEsportivo(null)
        files.calendario_esportivo = null
        break;
      case 'ata_eleicao_posse_atual_diretoria':
        setAtaEleicaoAtualDiretoria(null)
        files.ata_eleicao_posse_atual_diretoria = null
        break;
      case 'estatuto':
        setEstatuto(null)
        files.estatuto = null
        break;
      case 'cnpj':
        setCnpj(null)
        files.cnpj = null
        break;
      case 'identidade_presidente':
        setIdentidadePresidente(null)
        files.identidade_presidente = null
        break;
      case 'endereco_presidente':
        setEnderecoPresidente(null)
        files.endereco_presidente = null
        break;
    
      default:
        break;
    }
    
    await dispatch(updateProfileRequest({ files, type: profile.cnpj ? 'pj' : 'pf' }))
  }

  const filesUsers = () => {
    if (profile.cnpj) {
      return (
        <>
        {profile.cnpj && profile.files.cnpj ?
                  <Card
                    style={{
                      width: "400px",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                    title='CNPJ'
                    actions={[
                      <a href={profile.files.cnpj.url} target="_blank" rel="noopener noreferrer">
                        <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                      </a>,
                      <Button
                        style={{
                          background: "none"
                        }}
                        onClick={() => handleConfirmDelFile(profile.files.cnpj)}
                      >
                        <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                      </Button>
                    ]}
                  >
                    <FilePdfFilled style={{ fontSize: "2rem" }} />
                    {profile.files.cnpj.name}
                  </Card>
                  :
                  <Form
                    {...layoutLast}
                    name="basic"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={handleEditFiles}
                    style={{
                      width: "100vw",
                      marginTop: 16,
                      textAlign: "center" ,
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  >

                    {/* cpf */}
                    <Form.Item
                      name="cnpj"
                      label="CNPJ"
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!'
                        }
                      ]}
                    >
                      <Upload
                        name="cnpj"
                        beforeUpload={(file) => beforeUploadFile({ file })}
                        customRequest={(file) => customUploadFile({ setState: setCnpj, file })}
                        onRemove={(data) => handleDelFile({ state: cnpj, data })}
                        maxCount={1}
                        accept='application/pdf'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{
                            margin: '10px',
                            width: '28vw',
                            color: 'rgba(0, 190, 255, 0.9)',
                            borderColor: 'rgba(0, 190, 255, 0.9)'
                          }}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar
                      </Button>
                    </Form.Item>
                  </Form>
                }
                {profile.files.ata_eleicao_posse_atual_diretoria ?
                  <Card
                    style={{
                      width: "400px",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                    title='Ata de eleição e posse da atual diretoria'
                    actions={[
                      <a href={profile.files.ata_eleicao_posse_atual_diretoria.url} target="_blank" rel="noopener noreferrer">
                        <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                      </a>,
                      <Button
                        style={{
                          background: "none"
                        }}
                        onClick={() => handleConfirmDelFile(profile.files.ata_eleicao_posse_atual_diretoria)}
                      >
                        <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                      </Button>
                    ]}
                  >
                    <FilePdfFilled style={{ fontSize: "2rem" }} />
                    {profile.files.ata_eleicao_posse_atual_diretoria.name}
                  </Card>
                  :
                  <Form
                    {...layoutLast}
                    name="basic"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={handleEditFiles}
                    style={{
                      width: "100vw",
                      marginTop: 16,
                      textAlign: "center" ,
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  >

                    {/* ata_eleicao_posse_atual_diretoria */}
                    <Form.Item
                      name="ata_eleicao_posse_atual_diretoria"
                      label="Ata de eleição e posse da atual diretoria"
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!'
                        }
                      ]}
                    >
                      <Upload
                        name="ata_eleicao_posse_atual_diretoria"
                        beforeUpload={(file) => beforeUploadFile({ file })}
                        customRequest={(file) => customUploadFile({ setState: setAtaEleicaoAtualDiretoria, file})}
                        onRemove={(data) => handleDelFile({ state: ataEleicaoAtualDiretoria, data })}
                        maxCount={1}
                        accept='application/pdf'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{
                            margin: '10px',
                            width: '28vw',
                            color: 'rgba(0, 190, 255, 0.9)',
                            borderColor: 'rgba(0, 190, 255, 0.9)'
                          }}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar
                      </Button>
                    </Form.Item>
                  </Form>
                }
                {profile.files.calendario_esportivo ?
                  <Card
                    style={{
                      width: "400px",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                    title='Calendario Esportivo'
                    actions={[
                      <a href={profile.files.calendario_esportivo.url} target="_blank" rel="noopener noreferrer">
                        <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                      </a>,
                      <Button
                        style={{
                          background: "none"
                        }}
                        onClick={() => handleConfirmDelFile(profile.files.calendario_esportivo)}
                      >
                        <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                      </Button>
                    ]}
                  >
                    <FilePdfFilled style={{ fontSize: "2rem" }} />
                    {profile.files.calendario_esportivo.name}
                  </Card>
                  :
                  <Form
                    {...layoutLast}
                    name="basic"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={handleEditFiles}
                    style={{
                      width: "100vw",
                      marginTop: 16,
                      textAlign: "center" ,
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  >

                    {/* calendario_esportivo */}
                    <Form.Item
                      name="calendario_esportivo"
                      label="Calendario Esportivo"
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!'
                        }
                      ]}
                    >
                      <Upload
                        name="calendario_esportivo"
                        beforeUpload={(file) => beforeUploadFile({ file })}
                        customRequest={(file) => customUploadFile({ setState: setCalendarioEsportivo, file})}
                        onRemove={(data) => handleDelFile({ state: calendarioEsportivo, data })}
                        maxCount={1}
                        accept='application/pdf'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{
                            margin: '10px',
                            width: '28vw',
                            color: 'rgba(0, 190, 255, 0.9)',
                            borderColor: 'rgba(0, 190, 255, 0.9)'
                          }}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar
                      </Button>
                    </Form.Item>
                  </Form>
                }
                {profile.files.estatuto ?
                  <Card
                    style={{
                      width: "400px",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                    title='Estatuto'
                    actions={[
                      <a href={profile.files.estatuto.url} target="_blank" rel="noopener noreferrer">
                        <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                      </a>,
                      <Button
                        style={{
                          background: "none"
                        }}
                        onClick={() => handleConfirmDelFile(profile.files.estatuto)}
                      >
                        <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                      </Button>
                    ]}
                  >
                    <FilePdfFilled style={{ fontSize: "2rem" }} />
                    {profile.files.estatuto.name}
                  </Card>
                  :
                  <Form
                    {...layoutLast}
                    name="basic"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={handleEditFiles}
                    style={{
                      width: "100vw",
                      marginTop: 16,
                      textAlign: "center" ,
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  >

                    {/* estatuto */}
                    <Form.Item
                      name="estatuto"
                      label="Estatuto"
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!'
                        }
                      ]}
                    >
                      <Upload
                        name="estatuto"
                        beforeUpload={(file) => beforeUploadFile({ file })}
                        customRequest={(file) => customUploadFile({ setState: setEstatuto, file})}
                        onRemove={(data) => handleDelFile({ state: estatuto, data })}
                        maxCount={1}
                        accept='application/pdf'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{
                            margin: '10px',
                            width: '28vw',
                            color: 'rgba(0, 190, 255, 0.9)',
                            borderColor: 'rgba(0, 190, 255, 0.9)'
                          }}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar
                      </Button>
                    </Form.Item>
                  </Form>
                }
                {/* {profile.files.identidade_presidente ?
                  <Card
                    style={{
                      width: "400px",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                    title='Identidade Presidente'
                    actions={[
                      <a href={profile.files.identidade_presidente.url} target="_blank" rel="noopener noreferrer">
                        <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                      </a>,
                      <Button
                        style={{
                          background: "none"
                        }}
                        onClick={() => handleConfirmDelFile(profile.files.identidade_presidente)}
                      >
                        <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                      </Button>
                    ]}
                  >
                    <FilePdfFilled style={{ fontSize: "2rem" }} />
                    {profile.files.identidade_presidente.name}
                  </Card>
                  :
                  <Form
                    {...layoutLast}
                    name="basic"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={handleEditFiles}
                    style={{
                      width: "100vw",
                      marginTop: 16,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  > */}

                    {/* identidade_presidente */}
                    {/* <Form.Item
                      name="identidade_presidente"
                      label="Identidade Presidente"
                      labelCol={{  offset: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!'
                        }
                      ]}
                    >
                      <Upload
                        name="identidade_presidente"
                        beforeUpload={(file) => beforeUploadFile({ file })}
                        customRequest={(file) => customUploadFile({ setState: setIdentidadePresidente, file})}
                        onRemove={(data) => handleDelFile({ state: identidadePresidente, data })}
                        maxCount={1}
                        accept='application/pdf'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{
                            margin: '10px',
                            width: '28vw',
                            color: 'rgba(0, 190, 255, 0.9)',
                            borderColor: 'rgba(0, 190, 255, 0.9)'
                          }}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar
                      </Button>
                    </Form.Item>
                  </Form>
                }

                {profile.files.endereco_presidente ?
                  <Card
                    style={{
                      width: "400px",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                    title='comprovante Endereço Presidente'
                    actions={[
                      <a href={profile.files.endereco_presidente.url} target="_blank" rel="noopener noreferrer">
                        <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                      </a>,
                      <Button
                        style={{
                          background: "none"
                        }}
                        onClick={() => handleConfirmDelFile(profile.files.endereco_presidente)}
                      >
                        <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                      </Button>
                    ]}
                  >
                    <FilePdfFilled style={{ fontSize: "2rem" }} />
                    {profile.files.endereco_presidente.name}
                  </Card>
                  :
                  <Form
                    {...layoutLast}
                    name="basic"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={handleEditFiles}
                    style={{
                      width: "100vw",
                      marginTop: 16,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  > */}

                    {/* endereco_presidente */}
                    {/* <Form.Item
                      name="endereco_presidente"
                      label="comprovante Endereço Presidente"
                      labelCol={{  offset: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!'
                        }
                      ]}
                    >
                      <Upload
                        name="endereco_presidente"
                        beforeUpload={(file) => beforeUploadFile({ file })}
                        customRequest={(file) => customUploadFile({ setState: setEnderecoPresidente, file})}
                        onRemove={(data) => handleDelFile({ state: enderecoPresidente, data })}
                        maxCount={1}
                        accept='application/pdf'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{
                            margin: '10px',
                            width: '28vw',
                            color: 'rgba(0, 190, 255, 0.9)',
                            borderColor: 'rgba(0, 190, 255, 0.9)'
                          }}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar
                      </Button>
                    </Form.Item>
                  </Form> */}
                
        </>
      )
    } else if (profile.cpf) {
      return (
        <>
          {profile.files.cpf ?
                  <Card
                    style={{
                      width: "400px",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                    title='CPF'
                    actions={[
                      <a href={profile.files.cpf.url} target="_blank" rel="noopener noreferrer">
                        <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                      </a>,
                      <Button
                        style={{
                          background: "none"
                        }}
                        onClick={() => handleConfirmDelFile(profile.files.cpf)}
                      >
                        <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                      </Button>
                    ]}
                  >
                    <FilePdfFilled style={{ fontSize: "2rem" }} />
                    {profile.files.cpf.name}
                  </Card>
                  :
                  <Form
                    {...layoutLast}
                    name="basic"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={handleEditFiles}
                    style={{
                      width: "100vw",
                      marginTop: 16,
                      textAlign: "center" ,
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  >

                    {/* cpf */}
                    <Form.Item
                      name="cpf"
                      label="CPF"
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!'
                        }
                      ]}
                    >
                      <Upload
                        name="cpf"
                        beforeUpload={(file) => beforeUploadFile({ file })}
                        customRequest={(file) => customUploadFile({ setState: setCpf, file })}
                        onRemove={(data) => handleDelFile({ state: cpf, data })}
                        maxCount={1}
                        accept='application/pdf'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{
                            margin: '10px',
                            width: '28vw',
                            color: 'rgba(0, 190, 255, 0.9)',
                            borderColor: 'rgba(0, 190, 255, 0.9)'
                          }}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar
                      </Button>
                    </Form.Item>
                  </Form>
                }
                {profile.files.clipping ?
                  <Card
                    style={{
                      width: "400px",
                      marginTop: 16,
                      textAlign: "center" 
                    }}
                    title='Currículo'
                    actions={[
                      <a href={profile.files.clipping.url} target="_blank" rel="noopener noreferrer">
                        <FolderOpenFilled key="ellipsis" style={{ fontSize: "1.5rem" }} />
                      </a>,
                      <Button
                        style={{
                          background: "none"
                        }}
                        onClick={() => handleConfirmDelFile(profile.files.clipping)}
                      >
                        <DeleteFilled key="delete" style={{ fontSize: "1.5rem" }} />
                      </Button>
                    ]}
                  >
                    <FilePdfFilled style={{ fontSize: "2rem" }} />
                    {profile.files.clipping.name}
                  </Card>
                  :
                  <Form
                    {...layoutLast}
                    name="basic"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={handleEditFiles}
                    style={{
                      width: "100vw",
                      marginTop: 16,
                      textAlign: "center" ,
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  >

                    {/* clipping */}
                    <Form.Item
                      name="clipping"
                      label="Clipping"
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!'
                        }
                      ]}
                    >
                      <Upload
                        name="clipping"
                        beforeUpload={(file) => beforeUploadFile({ file })}
                        customRequest={(file) => customUploadFile({ setState: setClipping, file})}
                        onRemove={(data) => handleDelFile({ state: clipping, data })}
                        maxCount={1}
                        accept='application/pdf'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{
                            margin: '10px',
                            width: '28vw',
                            color: 'rgba(0, 190, 255, 0.9)',
                            borderColor: 'rgba(0, 190, 255, 0.9)'
                          }}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Salvar
                      </Button>
                    </Form.Item>
                  </Form>
                }
        </>

      )
    }
  }

  return (
    <Container>
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        layout="vertical"
        style={{
          margin: "10px"
        }}
        title={
          <div className="title">
            <strong>
              {/* {profile.razao_social || profile.nome || ''} */}
              <small>Última atualização - {moment(profile.updatedAt).utc("America/Alagoas").format("DD/MM/YYYY HH:mm")} </small>
            </strong>
          </div>
        }
      >
        <Descriptions.Item
          span={3}
          style={{
            textAlign: "center"
          }}
        >
          <FaIdCardAlt size={60} color={"rgba(0, 180, 255, 0.9)"} />
        </Descriptions.Item>
        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              { profile.cnpj ? 'Nome da Instituição:' : 'Nome: '}
            </strong>
          }
        >
          <small>
            {/* { profile.cnpj.replaceAll(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5") || profile.cpf.replaceAll(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')} */}
            { profile.nome || profile.razao_social || '' }
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              { profile.cnpj ? 'CNPJ:' : 'CPF: '}
            </strong>
          }
        >
          <small>
            {/* { profile.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5") || profile.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')} */}
            { profile.cnpj || profile.cpf}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Telefone:
            </strong>
          }
        >
          <small>
            {profile.telefone.replace(/^(\d\d)(\d{4})(\d)/,"($1) $2-$3")}
            {/* {profile.telefone} */}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Email:
            </strong>
          }
        >
          <small>
            {profile.email}
          </small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              { profile.data_nascimento ? 'Data de Nascimento: ' : 'Data de criação: ' }
            </strong>
          }
        >
           <small>{moment(profile.data_nascimento || profile.data_criacao).utc("America/Alagoas").format("DD/MM/YYYY")}</small>
        </Descriptions.Item>

        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Modalidade:
            </strong>
          }
        >
          <small>{profile.modalidade}</small>
        </Descriptions.Item>

        { profile.raca_cor &&
            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Raça / Cor:
                </strong>
              }
            >
              <small>{profile.raca_cor}</small>
            </Descriptions.Item>
        }

        { profile.genero &&
            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Gênero:
                </strong>
              }
            >
              <small>{profile.genero}</small>
            </Descriptions.Item>
        }

        { profile.classificacao &&
          <Descriptions.Item 
            label={
              <strong
                style={{
                  color: "rgba(0, 180, 255, 0.9)"
                }}
              >
                Classificação:
              </strong>
            }
          >
            <small>{!profile.classificacao && ''}</small>
            <small>{profile.classificacao === 'federacao' && 'Federação'}</small>
            <small>{profile.classificacao === 'associacao' && 'Associação'}</small>
            <small>{profile.classificacao === 'clube' && 'Clube'}</small>
            <small>{profile.classificacao === 'liga' && 'Liga'}</small>
            <small>{profile?.classificacao === 'outros' && 'Outros'}</small>
          </Descriptions.Item>
        }

        { profile.num_filiados &&
          <Descriptions.Item 
            label={
              <strong
                style={{
                  color: "rgba(0, 180, 255, 0.9)"
                }}
              >
                Número de filiados:
              </strong>
            }
          >
            <small>{profile.num_filiados}</small>
          </Descriptions.Item>
        }

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Status:
                </strong>
              }
            >
              <small>{profile.status === 'em_analise' && 'Em análise'}</small>
              <small>{profile.status === 'valido' && 'Válido'}</small>
              <small>{profile.status === 'invalido' && 'Inválido'}</small>
            </Descriptions.Item>
      </Descriptions>
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        layout="vertical"
        style={{
          margin: "10px"
        }}
        title={
          <div className="title">
            <strong>
              <small>Endereço</small>
            </strong>
          </div>
        }
      >
      <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Logradouro:
            </strong>
          }
        >
          <small>{profile.rua}</small>
        </Descriptions.Item>
        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Número:
            </strong>
          }
        >
          <small>{profile.numero}</small>
        </Descriptions.Item>
        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Bairro:
            </strong>
          }
        >
          <small>{profile.bairro}</small>
        </Descriptions.Item>
        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Complemento:
            </strong>
          }
        >
          <small>{profile.complemento ? profile.complemento : '-'}</small>
        </Descriptions.Item>
        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              CEP:
            </strong>
          }
        >
          <small>{profile.cep}</small>
        </Descriptions.Item>
        <Descriptions.Item 
          label={
            <strong
              style={{
                color: "rgba(0, 180, 255, 0.9)"
              }}
            >
              Município:
            </strong>
          }
        >
          <small>{profile.municipio}</small>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
          bordered
          size="small"
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          layout="vertical"
          style={{
            margin: "10px"
          }}
          title={
            <div className="title">
              <strong>
                <small>Atividade</small>
              </strong>
            </div>
          }
      >
        { profile.cpf &&
          <>
            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Profissional de educação física:
                </strong>
              }
            >
              <small>{profile?.profissional_edf ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  CREF:
                </strong>
              }
            >
              <small>{profile?.cref ? profile?.cref : '-'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
              }}
            >
                  Nível de atuação:
                </strong>
              }
            >
              <small>{profile.nivel_atuacao}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
              }}
            >
                  Função:
                </strong>
              }
              >
              <small>{profile.funcao}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
              }}
            >
                  Recebe remuneração pela função?
                </strong>
              }
              >
              <small>{profile.recebe_remuneracao ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Valor da remuneração:
                </strong>
              }
            >
              <small>{!profile.valor_remuneracao && '-'}</small>
              <small>{profile.valor_remuneracao === 'ate_um_salario' && 'Até um salário'}</small>
              <small>{profile.valor_remuneracao === 'ate_tres_salarios' && 'Até três salários'}</small>
              <small>{profile.valor_remuneracao === 'acima_de_tres_salarios' && 'Acima de três salários'}</small>
            </Descriptions.Item>

            
            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Possui alguma deficiência ?
                </strong>
              }
            >
              <small>{profile.pcd ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Tipo de deficiência:
                </strong>
              }
            >
              <small>{!profile.tipo_pcd && '-'}</small>
              <small>{profile.tipo_pcd === 'fisica' && 'Física'}</small>
              <small>{profile.tipo_pcd === 'intelectual' && 'Intelectual'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  É filiado a federação ?
                </strong>
              }
            >
              <small>{profile.filiado_federacao ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  É ou já foi beneficiado com o BOLSA-ATLETA ?
                </strong>
              }
            >
              <small>{profile.beneficiado_bolsa_atleta ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Tipo de bolsa:
                </strong>
              }
            >
              <small>{!profile.tipo_bolsa && '-'}</small>
              <small>{profile.tipo_bolsa === 'municipal' && 'Municipal'}</small>
              <small>{profile.tipo_bolsa === 'estadual' && 'Estadual'}</small>
              <small>{profile.tipo_bolsa === 'federal' && 'Federal'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Siatuação da Bolsa
                </strong>
              }
            >
              <small>{!profile.tipo_bolsa && '-'}</small>
              <small>{profile.situacao_bolsa === 'ativa' && 'Ativa'}</small>
              <small>{profile.situacao_bolsa === 'inativa' && 'Inativa'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              span={3}
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Breve Histórico
                </strong>
              }
            >
              <small>{profile.breve_historico}</small>
            </Descriptions.Item>
          </>
        }

        {profile.cnpj &&
          <>
            <Descriptions.Item 
                label={
                  <strong
                    style={{
                      color: "rgba(0, 180, 255, 0.9)"
                }}
              >
                  Sede propria ?
                  </strong>
                }
              >
                <small>{profile.sede_propria ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
              }}
            >
                Possui espaço adequado para treinamento da modalidade ?
                </strong>
              }
            >
              <small>{profile.espaco_adequado ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
              }}
            >
                Desenvolve esporte para pessoas com deficiência ?
                </strong>
              }
            >
              <small>{profile.desenvolve_para_pcd ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
              }}
            >
                Recebe repasse de recurso ?
                </strong>
              }
            >
              <small>{profile.recebe_repasse_recurso ? 'Sim' : 'Não'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Origem do recurso ?
                </strong>
              }
            >
              <small>{!profile.origem_recurso && '-'}</small>
              <small>{profile.origem_recurso === 'municipal' && 'Municipal'}</small>
              <small>{profile.origem_recurso === 'estadual' && 'Estadual'}</small>
              <small>{profile.origem_recurso === 'federal' && 'Federal'}</small>
              <small>{profile.origem_recurso === 'confederacao' && 'Confederação'}</small>
              <small>{profile.origem_recurso === 'privado' && 'Privado'}</small>
            </Descriptions.Item>

            <Descriptions.Item 
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
              }}
            >
                Período de vigência da direção/presidência
                </strong>
              }
            >
              <small>{profile?.vigencia_direcao_inicio && moment(profile?.vigencia_direcao_inicio).utc("America/Alagoas").format("MM/YYYY")} </small> - {' '} 
              <small>{profile?.vigencia_direcao_fim &&  moment(profile?.vigencia_direcao_fim).utc("America/Alagoas").format("MM/YYYY")} </small>
            </Descriptions.Item>

            <Descriptions.Item 
              span={3}
              label={
                <strong
                  style={{
                    color: "rgba(0, 180, 255, 0.9)"
                  }}
                >
                  Breve histórico da Instituição
                </strong>
              }
            >
              <small>{profile.breve_historico}</small>
            </Descriptions.Item>
          </>
        }
      </Descriptions>

      {profile.cnpj &&
          <>
            <Descriptions
              bordered
              size="small"
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              layout="vertical"
              style={{
                margin: "10px"
              }}
              title={
                <div className="title">
                  <strong>
                    <small>Responsável Legal</small>
                  </strong>
                </div>
              }
            >
              <Descriptions.Item 
                label={
                  <strong
                    style={{
                      color: "rgba(0, 180, 255, 0.9)"
                    }}
                  >
                    Nome:
                  </strong>
                }
              >
                <small>{profile.nome_responsavel}</small>
              </Descriptions.Item>

              <Descriptions.Item 
                label={
                  <strong
                    style={{
                      color: "rgba(0, 180, 255, 0.9)"
                    }}
                  >
                    Email:
                  </strong>
                }
              >
                <small>{profile.email_responsavel}</small>
              </Descriptions.Item>

              <Descriptions.Item 
                label={
                  <strong
                    style={{
                      color: "rgba(0, 180, 255, 0.9)"
                    }}
                  >
                    Telefone:
                  </strong>
                }
              >
                <small>{profile.telefone_responsavel}</small>
              </Descriptions.Item>

            </Descriptions>
          </>
        }

      <InfoEmpresa>
        {profile && (
          <Tabs 
            defaultActiveKey="1" 
            type="card"
            size="small"
            style={{
              padding: "10px"
            }}
          >
            {/* Card 1 */}
            
            <TabPane tab="Arquivos" key="1" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {filesUsers()}
            </TabPane>
          </Tabs>

        )}
      </InfoEmpresa>
    </Container>
  );
}

export default DashUsers;
