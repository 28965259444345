import styled from "styled-components";

export const Wrapper = styled.div`
  /* height: 100vh; */
  /* background: linear-gradient(-30deg, #5ce1e6, #5271ff); */
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
  /* height: 100vh; */

  /* height: 100vh; */

  display: flex;
  /* align-items: stretch; */
  /* flex-direction: column; */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column; 
  /* justify-content: center; */

  /* width: 30vw; */
  /* max-width: 390px; */

  @media (max-width: 700px) {
    padding: 15px;
    width: 85vw;
  }
`;
