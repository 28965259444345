import styled from 'styled-components'
import { shade } from 'polished'

export const Container = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  width: 100%;
  max-width: 550px;

  img {
    width: 28vw;
  }

  #gov {
    img {
      width: 145px;
      padding: 5px;
    }

    #selaj {
      width: 170px;
      height: 60px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;

  }

  #disable {
    opacity: 0.3;
  }

  h1 {
    font-size: 1.9rem;
    margin: 10px;
    font-weight: 500;
    color: rgba(0, 60, 170, 0.9)
  }

  button {
    margin: 20px 0 0;
    width: 30vw;
    max-width: 400px;

    background: #5271ff;

    &:hover {
      background: ${shade(0.07, '#5271ff')};
    }
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    display: flex;
    justify-content: top;
    margin: 0;
    
    img {
      width: 70vw;
      
    }

    button {
      margin: 20px 0 0;
      width: 80vw;
    }
  
  }
`