import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    Form,
    Input,
    Button,
    Tabs
} from 'antd'

import { updateProfileRequest } from "../../store/modules/user/actions";

import { Container } from "./styles";

function EditProfileSelaj() {
    const { TabPane } = Tabs;

    const dispatch = useDispatch();

    const { profile } = useSelector((state) => state.user);
    
    const onFinish = async (data) => {
        dispatch(updateProfileRequest({ data, type: 'selaj' }))
    };
  
  return (
      <Container>
          <Tabs tabPosition="top"
            style={{
                color: "rgba(7, 128, 239, 0.9)"
            }}
        >
            <TabPane tab="Dados" key="1">
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={
                        {
                            ...profile,
                            password: ''
                        }
                    }
                >
                    <Form.Item
                        label="Nome"
                        name="nome"
                        rules={[
                            {
                                required: true,
                                message: 'Campo obrigatório!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Cargo"
                        name="cargo"
                        rules={[
                            {
                                required: true,
                                message: 'Campo obrigatório!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                type: "email",
                                required: true,
                                message: 'Campo obrigatório!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            className="button"
                            type="primary"
                            htmlType="submit"
                            style={{ 
                                background: "rgba(7, 128, 239, 0.8)",
                                border: 0,
                                marginLeft: "150px",
                                marginTop: '30px'
                            }}
                        >
                            Salvar alterações
                        </Button>
                    </Form.Item>

                </Form>
            </TabPane>

            <TabPane tab="Acesso" key="2">
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={
                        {
                            ...profile,
                            password: ''
                        }
                    }
                >

                    <Form.Item
                        label="Senha Atual"
                        name="oldPassword"
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Nova Senha"
                        name="password"
                        rules={[
                            {
                                min: 8,
                                message: 'Confirmação do password é obrigatória!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                            
                    <Form.Item
                        label="Confirme sua nova senha"
                        dependencies={['password']}
                        name="confirmPassword"
                        hasFeedback
                        rules={[
                            {
                                // required: true,
                                message: 'Confirmação do password é obrigatória!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Password não confere!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            className="button"
                            type="primary"
                            htmlType="submit"
                            style={{ 
                                background: "rgba(7, 128, 239, 0.8)",
                                border: 0,
                                marginLeft: "150px",
                                marginTop: '30px'
                            }}
                        >
                            Salvar alterações
                        </Button>
                    </Form.Item>

                </Form>
            </TabPane>
        </Tabs>
      
      </Container>
  )
}

export default EditProfileSelaj;