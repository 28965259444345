import React, { useState } from 'react'
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Switch,
  Steps,
  message,
  Upload,
  Spin
} from 'antd'
import moment from 'moment'
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons'

import {
  isValidPhone,
  isValidCPF
} from '@brazilian-utils/brazilian-utils'
import cep from 'cep-promise'

import api from '../../services/api'
import history from '../../services/history'

import { 
  beforeUploadFile, 
  customUploadFile, 
  handleDelFile 
} from '../../helpers/uploadFileFirebase'

import { Container } from './styles'
import Options from '../../utils/options'

function FormPf () {
  const { Step } = Steps

  const steps = [
    {
      title: 'Dados',
      content: 'First-content'
    },
    {
      title: 'Endereço',
      content: 'Second-content'
    },
    {
      title: 'Atividade',
      content: 'First-content'
    },
    {
      title: 'Anexos',
      content: 'First-content'
    },
    {
      title: 'Confirmação',
      content: 'Last-content'
    }
  ]

  const [current, setCurrent] = useState(0)
  const [dataUser, setDataUser] = useState(null)
  const [endereco, setEndereco] = useState(null)
  const [loading, setLoading] = useState(false)
  
  const [cpf, setCpf] = useState(null)
  const [clipping, setClipping] = useState(null)

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']

  function checkCep (e) {
    setLoading(true)
    const numCep = e.target.value.replace(/\D/g, '')

    if (numCep.length === 8) {
      cep(numCep)
        .then((response) => {
          const {
            street: rua,
            neighborhood: bairro,
            city: municipio,
            cep,
            state: estado
          } = response

          setEndereco(
            {
              rua,
              bairro,
              municipio,
              cep,
              estado
            }
          )
          setLoading(false)
        })
        .catch((err) => {
          message.error('CEP não encontrado')
          setEndereco({})
        })
    }
  }

  //Novo
  const onPrevious = () => setCurrent(current - 1)

  const setSignup = (data) => {
    setDataUser({ ...dataUser, ...data })
    setCurrent(current + 1)
  }

  const setFiles = () => {
    setCurrent(current + 1)
  }

  const onFinish = async (data) => {
    try {
      const files = {
        cpf,
        clipping
      }
      const response = await api.post('/usuarios/create-pf', {
        ...dataUser,
        ...data,
        files
      })


      history.push('/sucess', response.data)
    } catch (error) {
      if (error.response.data === 'ER_102_EMAIL_COMPANY_ALREADY_EXISTS') {
        message.error('Email já cadastrado.')
      }
    }
  }

  return (
    <Container>
      <div id="steps">
        <Steps current={current} size="small">
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>

      {/* Steage 1 */}
      {/* Dados */}
      {
        current === 0 &&
          <>
            <Form
              layout='vertical'
              initialValues={{
                remember: true
              }}
              onFinish={setSignup}
              name="dados"
            >
              <Form.Item
                label="Nome"
                name="nome"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="CPF"
                name="cpf"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  },
                  () => ({
                    validator (rule, value) {
                      if (!value) {
                        return Promise.resolve()
                      }
                      if (value && value.replace(/\D/g, '') &&
                        isValidCPF(value)) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('CPF Inválido')
                      }
                    }
                  })
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Telefone"
                name="telefone"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  },
                  () => ({
                    validator (rule, value) {
                      if (value && value.replace(/\D/g, '') &&
                          isValidPhone(value)) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Telefone Inválido')
                      }
                    }
                  })
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Data Nascimento"
                name="data_nascimento"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
                wrapperCol={{
                  span: 16,
                  offset: 0
                }}
                labelAlign='right'
              >
                <DatePicker size='small' defaultValue={moment('01/01/1970', dateFormatList[0])} format={dateFormatList} />
              </Form.Item>

              <Form.Item
                label="Raça / Cor"
                name="raca_cor"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <Select>
                  <Select.Option value="Amarela">Amarela</Select.Option>
                  <Select.Option value="Branca">Branca</Select.Option>
                  <Select.Option value="Indigena">Indígena</Select.Option>
                  <Select.Option value="Negra">Negra</Select.Option>
                  <Select.Option value="Parda">Parda</Select.Option>
                  <Select.Option value="Não-declarada">Não Declarada</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Gênero"
                name="genero"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!'
                  }
                ]}
              >
                <Select>
                  <Select.Option value="Mulher">Mulher</Select.Option>
                  <Select.Option value="Homem">Homem</Select.Option>
                  <Select.Option value="Mulher-Trans">Mulher Trans</Select.Option>
                  <Select.Option value="Mulher-Travesti">Mulher Travesti</Select.Option>
                  <Select.Option value="Homem-Trans">Homem Trans</Select.Option>
                  <Select.Option value="Não-binario">Não-Binário</Select.Option>
                  <Select.Option value="Não-declarada">Não Declarada</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  className="button"
                  type="primary"
                  htmlType="submit"
                >
                  Próximo
                </Button>
                <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
                >
                  Anterior
                </Button>
              </Form.Item>

            </Form>
          </>
      }

      {/* Steage 2 */}
      {/* Endereço */}
      { current === 1 &&
        <>
          <Form
            layout='vertical'
            name="endereco"
            onFinish={setSignup}
            initialValues={{ remember: true }}
          >
            <Form.Item
              label="CEP"
              name="cep"
              onChange={checkCep}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Input />
            </Form.Item>

            { endereco &&
              <>
                <Form.Item
                  label="Rua"
                  name="rua"
                  initialValue={endereco.rua}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Número"
                  name="numero"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!'
                    },
                    // () => ({
                    //   validator (rule, value) {
                    //     if (!value) {
                    //       return Promise.resolve()
                    //     }
                    //     if (value && !isNaN(value)) {
                    //       return Promise.resolve()
                    //     } else {
                    //       return Promise.reject('Número Inválido')
                    //     }
                    //   }
                    // })
                    

                  ]}

                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Complemento"
                  name="complemento"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Bairro"
                  name="bairro"
                  initialValue={endereco.bairro}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Municipio"
                  name="municipio"
                  initialValue={endereco.municipio}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Estado"
                  name="estado"
                  initialValue={endereco.estado}
                  style={{ display: 'none' }}
                >

                  <Input style={{ display: 'none' }} />
                </Form.Item>
              </> 
            }
            {loading &&
                <div className='spin'>
                  <Spin />
                </div>
            }
            <Form.Item>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
              >
                Próximo
              </Button>
              <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
                >
                  Anterior
                </Button>
            </Form.Item>
          </Form>
        </>
      }

      {/* Steage 3 */}
      {/* Atividade */}
      { current === 2 &&
        <>
          <Form
            layout='vertical'
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={setSignup}
          >

            <Form.Item
              label="Modalidade"
              name='modalidade'
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Select
                placeholder="Selecione sua modalidade"
                options={Options.optionsModality}
              >
              </Select>
            </Form.Item>

            <Form.Item
              label="Profissional de educação física ?"
              name="profissional_edf"
              wrapperCol={{
                span: 13,
                offset: 0
              }}
            >
              <Switch checkedChildren="Sim" unCheckedChildren="Não" size='small' />
            </Form.Item>

            <Form.Item
              label="CREF"
              name="cref"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Função"
              name="funcao"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Recebe remuneração pela função ?"
              name="recebe_remuneracao"
              wrapperCol={{
                span: 14,
                offset: 0
              }}
            >
              
              <Switch checkedChildren="Sim" unCheckedChildren="Não" size='small' />
            </Form.Item>

            <Form.Item
              label="Valor da remuneração"
              name='valor_remuneracao'
            >
              <Select>
                <Select.Option value="ate_um_salario">Até um salário</Select.Option>
                <Select.Option value="ate_tres_salarios">Até três salário</Select.Option>
                <Select.Option value="acima_de_tres_salarios">Acima de três salário</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Possui alguma deficiência ?"
              name="pcd"
              wrapperCol={{
                span: 11,
                offset: 0
              }}
            >
              
              <Switch checkedChildren="Sim" unCheckedChildren="Não" size='small'/>
            </Form.Item>

            <Form.Item
              label="Tipo de deficiência"
              name='tipo_pcd'
            >
              <Select>
                <Select.Option value="fisica">Física</Select.Option>
                <Select.Option value="intelectual">Intelectual</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
              >
                Próximo
              </Button>
              <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
              >
                Anterior
              </Button>
            </Form.Item>
          </Form>
        </>
      }

      {/* Steage 4 */}
      {/* UPLOADS */}
      { current === 3 &&
        <>
          <Form
            layout='vertical'
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={setFiles}
          >

            {/* cpf */}
            <Form.Item
              name="cpf"
              label="CPF"
              tooltip={{
                title: 'Importar somente arquivos PDF de até 10MB!',
                icon: <InfoCircleOutlined />
              }}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Upload
                name="cpf"
                beforeUpload={(file) => beforeUploadFile({ file })}
                customRequest={(file) => customUploadFile({ setState: setCpf, file })}
                onRemove={(data) => handleDelFile({ state: cpf, data })}
                maxCount={1}
                accept='application/pdf'
              >
                <Button
                  className="btn-upload"
                  icon={<UploadOutlined />}
                  style={{
                    margin: '10px',
                    color: 'rgba(0, 190, 255, 0.9)',
                    border: '1px solid rgba(0, 190, 255, 0.9)'
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            {/* clipping */}
            <Form.Item
              name="clipping"
              label="Currículo"
              tooltip={{
                title: 'Importar somente arquivos PDF de até 10MB!',
                icon: <InfoCircleOutlined />
              }}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Upload
                name="clipping"
                beforeUpload={(file) => beforeUploadFile({ file })}
                customRequest={(file) => customUploadFile({ setState: setClipping, file})}
                onRemove={(data) => handleDelFile({ state: clipping, data })}
                maxCount={1}
                accept='application/pdf'
              >
                <Button
                  className="btn-upload"
                  icon={<UploadOutlined />}
                  style={{
                    margin: '10px',
                    color: 'rgba(0, 190, 255, 0.9)',
                    border: '1px solid rgba(0, 190, 255, 0.9)'
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
              >
                Próximo
              </Button>
              <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
              >
                Anterior
              </Button>
            </Form.Item>
          </Form>
        </>
      }

      {/* Steage 5 */}
      {/* confirmação */}
      { current === 4 &&
        <>
          <Form
            layout='vertical'
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
          >

            <Form.Item
              label="Nível de atuação"
              name="nivel_atuacao"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Select>
                <Select.Option value="amador">Amador</Select.Option>
                <Select.Option value="rendimento">Rendimento</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="É filiado a federação ?"
              name="filiado_federacao"
              wrapperCol={{
                span: 10,
                offset: 0
              }}
            >
              <Switch checkedChildren="Sim" unCheckedChildren="Não" size='small' />
            </Form.Item>

            
              <Form.Item
                label="É ou já foi beneficiado com o BOLSA-ATLETA ?"
                name="beneficiado_bolsa_atleta"
                wrapperCol={{
                  span: 17,
                  offset: 0
                }}
              >
                
                  <Switch checkedChildren="Sim" unCheckedChildren="Não" size='small'  />
              </Form.Item>

            <Form.Item
              label="Situação da bolsa"
              name="situacao_bolsa"
            >
              <Select>
                <Select.Option value="ativa">Ativa</Select.Option>
                <Select.Option value="inativa">Inativa</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Tipo da bolsa"
              name="tipo_bolsa"
            >
              <Select>
                <Select.Option value="municipal">Municipal</Select.Option>
                <Select.Option value="estadual">Estadual</Select.Option>
                <Select.Option value="federal">Federal</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Breve historico"
              name="breve_historico"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!'
                }
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  min: 8,
                  message: 'Password deve ter no mínimo 8 caracteres.!'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confime"
              dependencies={['password']}
              name="confirm"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Confirmação do password é obrigatória!'
                },
                ({ getFieldValue }) => ({
                  validator (rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Password não confere!')
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
              >
                Cadastrar
              </Button>
              <Button onClick={onPrevious} disabled={current === 0}
                className="btn"
                  type="primary"
                  htmlType="submit"
              >
                Anterior
              </Button>
            </Form.Item>
          </Form>
        </>
      }
    </Container>
  )
}

export default FormPf
